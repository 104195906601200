import React, { useState, useEffect, useCallback, useRef } from 'react';
import { withRouter, useHistory, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Row, Col, Dropdown } from 'react-bootstrap';
import { MdUploadFile, MdOutlineCreateNewFolder, MdKeyboardBackspace, MdFolder, MdMoreVert, MdOutlineSchedule, MdLock, MdLink } from 'react-icons/md'
import { BsFiles, BsCloudArrowUp } from 'react-icons/bs'
import { useDispatch, useSelector } from 'react-redux';
import { emitter } from '../utils/emitter';
import { GET_USAGE } from '../redux/actions/usage';
import { POPUP_EVENTS, HOME_EVENTS } from '../constants/events';
import CreateFolderPopup from '../components/Home/CreateFolderPopup';
import DetailPopup from '../components/Home/DetailPopup';
import FileContextMenu from '../components/Home/FileContextMenu';
import FolderContextMenu from '../components/Home/FolderContextMenu';
import FolderDetail from '../components/Home/FolderDetail';
import MovePopup from '../components/Home/MovePopup';
import Activites from '../components/Home/Activites';
import PinBar from '../components/PinBar';
import { Scrollbars } from 'react-custom-scrollbars';
import * as scrollbar from '../helpers/scrollbar';
import ChatModule from '../components/Home/ChatModule';
import SearchResult from '../components/Home/SearchResult';
import SearchForm from '../components/Home/SearchForm';
import UploadPopup from '../components/Home/UploadPopup';
import RenamePopup from '../components/Home/RenamePopup';
import TagPopup from '../components/Home/TagPopup';
import CheckoutPopup from '../components/Home/CheckoutPopup';
import CONFIG from '../constants/config';
import noFile from '../assets/images/nofile.png';
import {
    checkDocs,
    checkDocsHidden,
    moveDocs,
    getAllDocs
} from '../redux/actions/documents';
import {
    checkQuickAccess,
    checkQuickAccessHidden,
} from '../redux/actions/quickAccess';
import CheckinPopup from '../components/Home/CheckinPopup';
import { pasteFileCopied } from '../redux/actions/copiedFile';
import FileProperties from '../components/Home/FileProperties';
import { checkPath, checkPathBack } from '../redux/actions/path';
import FolderAreaContextMenu from '../components/Home/FolderAreaContextMenu';
import LinkFiles from '../components/Home/LinkFiles';
import LinkFilesMinimize from '../components/Home/LinkFilesMinimize';
import SkyLight from 'react-skylight';
import { checkOrgPlan } from '../services/authentication';
import { notifyError } from '../components/common/Alert';
import { logout } from '../redux/actions';
import VersionPopup from '../components/Home/VersionPopup';
import FolderItem from '../components/Home/Folder.Item';
import FilesComponent from '../components/FilesComponent';
import getDefaultThumbnail from '../utils/getDefaultThumbnail';
import { bytesToSize } from '../utils/string';
import moment from 'moment'
import ReactTooltip from 'react-tooltip';
import { checkFavorite, multipleSelectingFiles, singleSelectingFiles, removeSelectingFiles } from '../redux/actions/files';
import { getLinkedFiles, resetLinkPopup } from '../redux/actions/linkFilePopup';
import ListViewBlock from '../components/common/ListViewBlock';
import MenuIcon from '../assets/images/grid-icon.svg'
import ListIcon from '../assets/images/list-icon.svg'
import Folder from '../assets/images/folder-icon.svg';
import { activitiesBarToggle } from '../redux/actions/layout';
import { getFolderName } from '../utils/string';


const pwdPopupStyle = {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: '40%',
    margin: 'auto',
    height: 'fit-content',
    minHeight: '',
    borderRadius: '10px',
};

// function getShortFilePath(filePath) {
//     const filePathArr = filePath.split('/');
//     if (filePathArr.length === 1) {
//         return 'Root';
//     }
//     if (filePathArr.length === 2) {
//         return `${filePathArr[filePathArr.length - 2]}`;
//     }
//     return `${filePathArr[filePathArr.length - 2]}`;
// }

const dropdownText = [
    { id: 1, name: 'Name', value: 'name' },
    { id: 2, name: 'File Type', value: 'file-type' },
    { id: 3, name: 'Size', value: 'size' },
    { id: 4, name: 'Uploaded', value: 'uploaded' },
    { id: 5, name: 'Last Accessed', value: 'last-accessed' }
]


function Files({ match }) {

    const history = useHistory();
    const dispatch = useDispatch();
    const pwdPopup = useRef(null);
    const fileRef = useRef(null);
    const params = useParams();
    const folder_id = params.id ? params.id : null;

    // const folder_id = match.params.id ? match.params.id : null;
    const [firstId, setFirstId] = useState(null);
    const [folderCount, setFolderCount] = useState(6);
    const [filesCount, setFilesCount] = useState(6);
    const [isSearching, setIsSearching] = useState(false);
    const [searched, setSearched] = useState(false);
    const [searchInputFocus, setSearchInputFocus] = useState(false);
    const [searchResults, setSearchResults] = useState([]);
    // const [selectFiles, setSelectFiles] = useState([]);

    const { docsChecked: documentR, isLoading } = useSelector((s) => s.documents);
    const copiedFile = useSelector((s) => s.copiedFile);
    const files = useSelector((s) => s.files);
    const selectedFiles = useSelector((s) => s.multiSelection.selectedFiles);
    const isMultipleFiles = useSelector((s) => s.multiSelection.isMultipleFiles);
    const folders = useSelector((s) => s.folders);
    const allFolders = useSelector((s) => s.allFolders);
    const path = useSelector((s) => s.path);
    const userInfo = useSelector((s) => s.userInfo);
    const layout = useSelector((s) => s.layout);
    // console.log("folders****", folders);

    const [gridView, setGridView] = useState(true);
    const [defaultFiles, setDefaultFiles] = useState([])
    const [filesArray, setFilesArray] = useState([]);
    const [fileShowLoader, setFileShowLoader] = useState(false);

    // console.log("fileShowLoader", fileShowLoader);
    // console.log("filesArray", filesArray);
    const [foldersArray, setFoldersArray] = useState([]);
    const [sortArray, setSortArray] = useState('');

    useEffect(() => {
        setFilesArray(files);
        setDefaultFiles(files);
        setFoldersArray(folders);
    }, [folders, files])

    const gridviewHandler = () => {
        setGridView(!gridView);
    }


    const acceptMimeTypes = Object.values(CONFIG.ACCEPT_MIME_TYPES)
        .map((arr) => arr.join(','))
        .join(',');

    function allowDrop(event) {
        event.preventDefault();
    }

    const handleFileContextMenu = (e, file) => {
        e.preventDefault();
        let { clientY } = e;
        if (clientY + 335 >= window.innerHeight) {
            clientY = window.innerHeight - 335;
        }
        emitter.emit(POPUP_EVENTS.SHOW_FILE_CONTEXT_MENU, file, e.clientX, clientY);
    };

    const handleFolderContextMenu = (id, name, e) => {
        e.preventDefault();
        let { clientX } = e;
        let { clientY } = e;
        if (clientY + 300 >= window.innerHeight) {
            clientY = window.innerHeight - 300;
        }
        if (clientX + 320 >= window.innerWidth) {
            clientX = window.innerWidth - 400;
        }
        emitter.emit(
            POPUP_EVENTS.SHOW_FOLDER_CONTEXT_MENU,
            id,
            name,
            clientX,
            clientY
        );
    };

    function dragStart(event) {
        setFirstId(event.currentTarget.dataset.id);
    }

    function drop(event) {
        const [first_Type, first_Id] = firstId?.split('-');
        const [first_Type1, first_Id1] = event.currentTarget.dataset.id?.split('-');
        if (first_Type1 === 'fo') {
            if (first_Type === 'fi') {
                dispatch(moveDocs(first_Id, null, first_Id1));
            } else if (first_Type === 'fo') {
                dispatch(moveDocs(null, first_Id, first_Id1));
            }
        }
        event.preventDefault();
    }

    function dropHandler(ev) {
        emitter.emit(HOME_EVENTS.DROP_FILE_OR_FOLDER, ev);
    }
    function dragOverHandler(ev) {
        ev.preventDefault();
    }

    const handleGetUsage = useCallback(() => dispatch({ type: GET_USAGE }), [
        dispatch,
    ]);

    const showCreateFolderPopup = () => emitter.emit(POPUP_EVENTS.SHOW_CREATE_FOLDER, folder_id);

    const getMyDocs = useCallback(() => {
        handleGetUsage();
        if (documentR.includes(folder_id)) {
            // dont recall api
        } else {
            dispatch(checkDocs(folder_id));
            dispatch(getAllDocs())
            if (!folder_id) {
                dispatch(checkQuickAccess());
            }
        }
    }, [dispatch, documentR, folder_id, handleGetUsage]);

    function handleClick(id, name) {
        dispatch(checkQuickAccess());
        dispatch(checkPath({ id, name }));
        history.push(`/files/folder-detail/${id}`);
    }
    const handleClickPath = (id, name) => {
        if (id === 0) {
            dispatch(checkPath({ id: 0, name: 'Root' }));
            history.push('/files');
        } else if (path.findIndex((i) => i.id === id) === path.length - 1) {
            return false;
        } else {
            dispatch(checkPath({ id, name }));
            history.push(`/files/folder-detail/${id}`);
        }
    };

    useEffect(() => {
        checkOrgPlan()
            .then(() => { })
            .catch((err) => {
                if (err.response.data && err.response.data.status_code === 402) {
                    notifyError(err.response.data.data);
                    history.push('/user');
                } else if (err.response.data && err.response.data.status_code === 410) {
                    notifyError(err.response.data.data);
                    dispatch(logout());
                }
            });
        const interval = setInterval(() => {
            dispatch(checkDocsHidden(folder_id));
            if (!folder_id) {
                dispatch(checkQuickAccessHidden());
            }
        }, 30000);
        return () => {
            clearInterval(interval);
        };

    }, []);

    useEffect(() => {
        getMyDocs();
    }, [getMyDocs]);

    useEffect(() => {
        if (userInfo.pwd_validity_period <= 7 && pwdPopup.current) {
            pwdPopup.current.show();
        }
    }, [userInfo]);

    function handleUploadFile(e) {
        emitter.emit(HOME_EVENTS.HANLDE_UPLOAD_FILE, e);
    }

    function handleUploadFiles(e) {
        emitter.emit(HOME_EVENTS.HANLDE_UPLOAD_FILES, e);
    }

    const propmtNewFolderName = () => {
        showCreateFolderPopup();
    };

    function handleCreateFolders(e) {
        e.preventDefault();
        const { clientX } = e;
        let { clientY } = e;
        if (clientY + 300 >= window.innerHeight) {
            clientY = window.innerHeight - 300;
        }
        emitter.emit(POPUP_EVENTS.SHOW_FOLDER_AREA_CONTEXT_MENU, clientX, clientY);
    }

    function handlePasteCopiedFile() {
        if (copiedFile > 0) {
            dispatch(pasteFileCopied(copiedFile, folder_id));
        }
    }

    const handleGoBack = () => {
        dispatch(checkPathBack());
        history.goBack();
        dispatch(checkQuickAccess());

    };

    const handlerSelectMultiple = (file) => {
        // console.log(file);
        if (window.event.ctrlKey) {
            dispatch(multipleSelectingFiles(file))
        } else {
            dispatch(singleSelectingFiles(file))
        }
    }

    const btnId = document.getElementById('dropdown-upload');
    if (btnId) {
        btnId.className = "";
        btnId.className = "dropdown-toggle btn-uploads";
        // btnId.classList.remove('.dropdown-toggle .btn .btn-primary')
    }

    const showFolderToggle = () => {
        setFolderCount(folderCount > 6 ? 6 : Array.isArray(folders) ? folders.length : 6)
    }
    const showFileToggle = () => {
        // if(filesCount == 6){

        //     setFileShowLoader(true)
        // }
        setFilesCount(filesCount > 6 ? 6 : Array.isArray(files) ? files.length : 6)

    }

    const clearSearch = () => {
        setIsSearching(false)
        setSearchInputFocus(false)
        setSearchResults([]);
        setSearched(false);
    }

    // useEffect(() => {
    //     /**
    //      * Alert if clicked on outside of element
    //      */
    //     function handleClickOutside(event) {
    //         if (fileRef.current && !fileRef.current.contains(event.target)) {
    //             dispatch(removeSelectingFiles())
    //             // console.log('working')
    //         }
    //     }
    //     // Bind the event listener
    //     document.addEventListener("dblclick", handleClickOutside);
    //     return () => {
    //         // Unbind the event listener on clean up
    //         document.removeEventListener("dblclick", handleClickOutside);
    //     };
    // }, [fileRef]);

    function nameFormatter(cell, row) {
        return (
            <span className='d-flex align-items-center' data-tip={row.new_name || row.folder_name} data-for="fileName">
                <img className="folder-icon" src={row.folder_id ? Folder : getDefaultThumbnail(row.type)} alt="folder" />
                <span style={{ whiteSpace: 'nowrap', maxWidth: '100px', overflow: 'hidden', textOverflow: 'ellipsis' }}>{row.new_name || row.folder_name}</span>
                <ReactTooltip id="fileName" />
            </span>
        );
    }

    // const fileTypeFormatter = (cell, row) => {
    //     return row.folder_id ? <span className='folder-name'>File Folder</span> : <span className='text-uppercase'>{row.type}</span>
    // }

    const folderPathFormatter = (cell, row) => {
        let folderName = '';
        if (!row.parent_id) {
            folderName = 'Root'
        } else {
            folderName = getFolderName(row.parent_id, allFolders)

        }
        return (
            <div className="folder-path-wraps" data-for="folderName" data-tip={folderName}>
                <span className='folder-icon'><MdFolder /></span>
                <span style={{ whiteSpace: 'nowrap', maxWidth: '55px', overflow: 'hidden', textOverflow: 'ellipsis' }}>{folderName}</span>
                <ReactTooltip id="folderName" />
            </div>
        )
    }

    const sizeFormatter = (cell, row) => {
        return row.size ? bytesToSize(row.size) : '-'
    }

    const accessedFormatter = (cell, row) => {
        const date = row.updated_at ? new Date(row.updated_at) : '';
        return (
            row.updated_at ?
                <div className='icon-with-text'>
                    <span className='icon'><MdOutlineSchedule /></span>
                    {moment(date).format('MMM DD, YYYY')}
                </div>
                :
                '-'
        )
    }
    const uploadFormatter = (cell, row) => {
        // console.log(row);
        const date = row.created_at ? new Date(row.created_at) : '';
        return (
            <>
                <div className='icon-with-text'>
                    <span className='icon'><BsCloudArrowUp /></span>
                    {moment(date).format('MMM DD, YYYY')}
                </div>
                <div className="files-dropdown table-td-drop">
                    {row.folder_id ?
                        <button type="button" data-for="menu" data-tip="Menu" style={{ lineHeight: '0' }} onClick={(e) => handleFolderContextMenu(row.folder_id, row.folder_name, e)}><MdMoreVert /></button>
                        :
                        <button type="button" data-for="menu" data-tip="Menu" style={{ lineHeight: '0' }} onClick={(e) => handleFileContextMenu(e, row)}><MdMoreVert /></button>
                    }
                    <ReactTooltip id="menu" />
                </div>
            </>
        )
    }

    const statusFormatter = (cell, row) => {
        return (
            <>
                <span className='status-td-div'>
                    <button
                        type="button"
                        data-tip="favorite"
                        data-for="favorite"
                        className={`btns-likes ${row.favorite ? 'active' : ''}`}
                        onClick={() =>
                            dispatch(
                                checkFavorite(row.file_id, !row.favorite, row)
                            )
                        }
                    >
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 5.09C13.09 3.81 14.76 3 16.5 3C19.58 3 22 5.42 22 8.5C22 12.2769 18.6056 15.3549 13.4627 20.0185L13.45 20.03L12 21.35L10.55 20.04L10.5105 20.0041C5.38263 15.3442 2 12.2703 2 8.5C2 5.42 4.42 3 7.5 3C9.24 3 10.91 3.81 12 5.09Z" fill="#DF5656" />
                        </svg>

                        <span>Favorite</span>
                    </button>

                    {row.checkout ?
                        <button
                            type="button"
                            data-tip="checkout"
                            data-for="checkout"
                            className='btn-checkout-check'
                        >
                            <MdLock />
                            <span>Checkout</span>
                        </button>

                        :
                        null
                    }
                    {row.link ?

                        <button
                            type="button"
                            style={{ lineHeight: '0' }}
                            className='link-btns'
                            data-tip=""
                            data-for="linkedfile"
                            onMouseOver={() => dispatch(getLinkedFiles(row.file_id))}
                            onMouseLeave={() => dispatch(resetLinkPopup())}
                        >
                            <MdLink />
                            <span>Linked</span>
                        </button>
                        :
                        null
                    }
                </span>
                <ReactTooltip id="linkedfile" />
                <ReactTooltip id="favorite" />
                <ReactTooltip id="checkout" />
            </>
        )
    }

    const sortHandler = (e, item) => {
        e.preventDefault();
        const sortVal = item.value;
        if (sortArray === sortVal) {
            dispatch(checkDocs(folder_id));
            setSortArray('')
            // console.log(defaultFiles);
            setFilesArray(files)
            return true;
        } else {
            // console.log(files)
            setSortArray(sortVal);
            if (defaultFiles && defaultFiles.length > 0) {
                const fileSort = defaultFiles.sort((a, b) => {
                    switch (sortVal) {
                        case 'name':
                            if (a.new_name < b.new_name) { return -1; }
                            if (a.new_name > b.new_name) { return 1; }
                            return 0;
                        case 'file-type':
                            if (a.type < b.type) { return -1; }
                            if (a.type > b.type) { return 1; }
                            return 0;
                        case 'size':
                            if (a.size < b.size) { return -1; }
                            if (a.size > b.size) { return 1; }
                            return 0;
                        case 'uploaded':
                            const a_cdate = new Date(a.created_at);
                            const b_cdate = new Date(b.created_at);
                            return b_cdate - a_cdate;
                        case 'last-accessed':
                            const a_date = new Date(a.updated_at);
                            const b_date = new Date(b.updated_at);
                            return b_date - a_date;
                        default:
                            return null;
                    }

                });
                setFilesArray(fileSort);
            }
        }
    }

    const columns = [

        {
            dataField: 'new_name',
            text: 'Name',
            formatter: nameFormatter,
            sort: true,
            sortFunc: (a, b, order, dataField, row) => {
                if (order === 'asc') {
                    // if (a < b) { return -1; }
                    // if (a > b) { return 1; }
                    return 1;
                } else {
                    if (a > b) { return 1; }
                    if (a < b) { return -1; }
                    return 0;
                }
            }
        },
        {
            dataField: 'status',
            text: 'Status',
            sort: false,
            formatter: statusFormatter
        },
        // {
        //     dataField: 'type',
        //     text: 'File Type',
        //     sort: true,
        //     formatter: fileTypeFormatter
        // },
        {
            dataField: 'folder_name',
            text: 'Folder',
            sort: true,
            formatter: folderPathFormatter
        },
        // {
        //   dataField: 'member_icons',
        //   text: 'Member'
        // },
        {
            dataField: 'file_size',
            text: 'Size',
            sort: true,
            formatter: sizeFormatter
        },
        {
            dataField: 'updated_at',
            text: 'Last Accessed',
            sort: true,
            formatter: accessedFormatter
        },
        {
            dataField: 'created_at',
            text: 'Upload Date',
            sort: true,
            formatter: uploadFormatter
        }
    ];

    const handleOnSelect = (row, isSelect) => {
        handlerSelectMultiple(row)

    }
    const handleOnSelectAll = (isSelect, rows) => {
        handlerSelectMultiple(rows)

    }

    const selectRow = {
        mode: 'checkbox',
        clickToSelect: true,
        onSelect: handleOnSelect,
        onSelectAll: handleOnSelectAll,
        hideSelectColumn: true,
        bgColor: '#f5f6f8',
        selected: selectedFiles && selectedFiles.length > 0 ? selectedFiles.map((item) => item.id || item.file_id) : []
        // onDoubleClick: viewFileClick
    }

    const rowEvents = {
        // onDoubleClick: handleClick,
        onContextMenu: (e) => {
            if (isMultipleFiles) {
                handleFileContextMenu(e, selectedFiles?.[0])
            }
        }
    }

    return (
        <div className="d-flex d-xs-block">
            {/* {fileShowLoader &&
                <>
                    <div class="container-div">
                        <div class="loader-element">
                            <div></div>
                        </div>
                    </div>
                </>
            } */}
            <section
                className="main-section home-page-section content"
                id="main"
                onDrop={dropHandler}
                onDragOver={dragOverHandler}
                tabIndex="-1"
                onKeyDown={(e) => {
                    if (e.key === 'v' && e.ctrlKey) {
                        handlePasteCopiedFile();
                    }
                }}
            >
                <Scrollbars
                    renderView={scrollbar.renderView}
                    renderTrackHorizontal={scrollbar.renderTrackHorizontal}
                    renderTrackVertical={scrollbar.renderTrackVertical}
                    renderThumbHorizontal={scrollbar.renderThumb}
                    renderThumbVertical={scrollbar.renderThumb}
                    universal={true}
                >
                    <div className={`page-top-header ${match.url.split('/')[2] === 'folder-detail' ? 'mw-74' : ''} ${searched ? 'mw-75' : ''}`}>
                        {!searchInputFocus && <h1 className="page-title">My Files</h1>}
                        <SearchForm
                            searchInputFocus={searchInputFocus}
                            setSearched={setSearched}
                            setIsSearching={setIsSearching}
                            searched={searched}
                            setSearchResults={setSearchResults}
                            setSearchInputFocus={setSearchInputFocus}
                        />
                        {searched ?
                            <button type="button" className="dropdown-toggle btn-uploads" style={{ background: '#FCAF18' }} onClick={clearSearch}>Clear Search</button>
                            :
                            !layout.isMobileView && <Dropdown className="dropdown-wraps">
                                <Dropdown.Toggle id="dropdown-upload" className="dropdown-toggle btn-uploads">
                                    {/* <button type="button"  className="dropdown-toggle btn-uploads"> */}
                                    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clipPath="url(#clip0_118_1284)">
                                            <path d="M11.4286 5.42857H7.78571C7.66738 5.42857 7.57143 5.33262 7.57143 5.21429V1.57143C7.57143 0.979736 7.09169 0.5 6.5 0.5C5.90831 0.5 5.42857 0.979736 5.42857 1.57143V5.21429C5.42857 5.33262 5.33262 5.42857 5.21429 5.42857H1.57143C0.979736 5.42857 0.5 5.90831 0.5 6.5C0.5 7.09169 0.979736 7.57143 1.57143 7.57143H5.21429C5.33262 7.57143 5.42857 7.66738 5.42857 7.78571V11.4286C5.42857 12.0203 5.90831 12.5 6.5 12.5C7.09169 12.5 7.57143 12.0203 7.57143 11.4286V7.78571C7.57143 7.66738 7.66738 7.57143 7.78571 7.57143H11.4286C12.0203 7.57143 12.5 7.09169 12.5 6.5C12.5 5.90831 12.0203 5.42857 11.4286 5.42857Z" fill="white" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_118_1284">
                                                <rect width="12" height="12" fill="white" transform="translate(0.5 0.5)" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                    Upload File
                                    {/* </button> */}
                                </Dropdown.Toggle>
                                <Dropdown.Menu className='uploadBtn-menu'>
                                    <Dropdown.Item as="button" className="uploadBtn">
                                        {/* <button type="button" className="uploadBtn" onClick={closeDropdownUpload}> */}
                                        <i className='icon-upload'><MdUploadFile /></i>
                                        Upload file
                                        <input
                                            type="file"
                                            name="myfile"
                                            id="uploadfile"
                                            onChange={handleUploadFile}
                                            accept={acceptMimeTypes}
                                        />
                                        {/* </button> */}
                                    </Dropdown.Item>
                                    <Dropdown.Item as="button" className="uploadBtn">
                                        {/* <button type="button" className="" onClick={closeDropdownUpload}> */}
                                        <i className='icon-upload'><BsFiles /></i>
                                        Upload files
                                        <input
                                            type="file"
                                            name="files[]"
                                            id="uploadfile"
                                            multiple="multiple"
                                            onChange={handleUploadFiles}
                                            accept={acceptMimeTypes}
                                        />
                                        {/* </button> */}
                                    </Dropdown.Item>
                                    <Dropdown.Item as="button" className="uploadBtn" onClick={propmtNewFolderName}>
                                        {/* <button type="button" className='uploadBtn' onClick={propmtNewFolderName}> */}
                                        <i className='icon-upload'><MdOutlineCreateNewFolder /></i>
                                        Create folder
                                        {/* </button> */}
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        }
                    </div>
                    {
                        searched ?
                            <SearchResult
                                searchResults={searchResults}
                                isSearching={isSearching}
                                handleFileContextMenu={handleFileContextMenu}
                            />
                            :
                            null
                    }
                    {
                        isLoading ? (
                            <div
                                className="main-body no-file text-center"
                                onDrop={dropHandler}
                                onDragOver={dragOverHandler}

                            >
                                <img alt="" src={noFile} />
                                <p>Your documents are loading...</p>
                            </div>
                        ) : null
                    }
                    {!folders.filter((f) => f.parent_id === folder_id).length &&
                        !files.filter((f) => f.parent_id === folder_id).length &&
                        !searched ?
                        <div
                            className={`main-body no-file page-bottom-header tab-panel-custom`}
                            tabIndex="-1"
                            onKeyDown={(e) => {
                                if (e.key === 'v' && e.ctrlKey) {
                                    handlePasteCopiedFile();
                                }
                            }}
                            onContextMenu={(e) => {
                                if (
                                    ['main-body no-file page-bottom-header tab-panel-custom', 'main-body no-file mt-3', 'main-body no-file '].includes(
                                        e.target.className
                                    ) ||
                                    ['DIV', 'IMG', 'P'].includes(e.target.tagName)
                                ) {
                                    handleCreateFolders(e);
                                }
                            }}
                            // onDrop={dropHandler}
                            onDragOver={dragOverHandler}
                        >
                            {path.length > 1 && folder_id ? (
                                <div className="mainNav back-mainNav mb-3">
                                    <i
                                        className="icon-arrow-left"
                                        onClick={handleGoBack}
                                    >
                                        <MdKeyboardBackspace />
                                    </i>
                                    <div className="path">
                                        <i className="icon-folder"><MdFolder /></i>
                                        &nbsp;
                                        {path.map((i) => (
                                            <span
                                                key={i.id}
                                                onClick={() => handleClickPath(i.id, i.name)}
                                            >
                                                {i.id ? '/files' : ''}&nbsp;{i.name}&nbsp;
                                            </span>
                                        ))}
                                    </div>
                                </div>
                            ) :
                                ''
                            }
                            {!isLoading ?
                                <>
                                    <img src={noFile} alt="" />
                                    <p>There are no documents.</p>
                                    <p>
                                        <strong>Drop files</strong> anywhere to uploador{' '}
                                        <strong>Upload files.</strong>
                                    </p>
                                </>
                                :
                                null
                            }
                        </div>
                        :
                        null
                    }
                    {
                        match.url.split('/')[2] === 'folder-detail' &&
                            !searched &&
                            !isLoading &&
                            (folders.filter((f) => f.parent_id === folder_id).length ||
                                files.filter((f) => f.parent_id === folder_id).length) ? (
                            <FolderDetail
                                handleFileContextMenu={handleFileContextMenu}
                                handlePasteCopiedFile={handlePasteCopiedFile}
                                handleClick={handleClick}
                                handleClickPath={handleClickPath}
                                handleDrop={dropHandler}
                                handleDragOver={dragOverHandler}
                                handleFolderContextMenu={handleFolderContextMenu}
                                handleCreateFolders={handleCreateFolders}
                                drop={drop}
                                allowDrop={allowDrop}
                                dragStart={dragStart}
                                files={files.filter((f) => f.parent_id === folder_id)}
                                folders={folders.filter((f) => f.parent_id === folder_id)}
                                onSelect={handlerSelectMultiple}

                            />
                        ) : null
                    }
                    {
                        match.url.split('/')[2] !== 'folder-detail' &&
                            !searched &&
                            !isLoading &&
                            (folders.filter((f) => f.parent_id === folder_id).length ||
                                files.filter((f) => f.parent_id === folder_id).length) ?
                            <div
                                className="page-bottom-header main-body"
                                // onDrop={dropHandler}
                                onDragOver={dragOverHandler}
                                tabIndex="-1"
                                onKeyDown={(e) => {
                                    if (e.key === 'v' && e.ctrlKey) {
                                        handlePasteCopiedFile();
                                    }
                                }}
                                onContextMenu={(e) => {
                                    if (
                                        [
                                            'page-bottom-header main-body',
                                            'mainNav',
                                            'recently-access',
                                            'folders',
                                            'folders tab-panel-custom, pt-0',
                                            'folders__title',
                                            'folders__items',
                                            'files',
                                            'files tab-panel-custom pt-0',
                                            // 'files-wraps',
                                            'files__title',
                                            'files__items',
                                            'file-recent',
                                            'folders__title',
                                        ].includes(e.target.className)
                                    ) {
                                        handleCreateFolders(e);
                                    }
                                }}
                            >
                                {folders.length ? (
                                    <div className="folders tab-panel-custom pt-0">
                                        <div className="d-flex align-items-start justify-content-between mt-4">
                                            <h3 className="file-title folders__title">Folder <span className='count-totals'>{folders && folders.length > 0 ? folders.filter((f) => f.parent_id === folder_id).length : 0}</span></h3>
                                            <div className='btns-groups-grids'>
                                                <button className="btn-outline-primary" onClick={showFolderToggle}>{folderCount > 6 ? 'Show Less' : 'Show all'}</button>

                                            </div>
                                        </div>
                                        {/* {gridView ? */}
                                        <div className='folders__items'>
                                            {folders.sort((a, b) => b.folder_id - a.folder_id) &&
                                                folders.filter((f) => f.parent_id === folder_id).slice(0, folderCount).map((item, i) => (
                                                    <FolderItem
                                                        key={item.folder_id}
                                                        folder={item}
                                                        handleClick={handleClick}
                                                        handleFolderContextMenu={handleFolderContextMenu}
                                                        dragStart={dragStart}
                                                        onDrop={drop}
                                                        onDragOver={allowDrop}
                                                    />
                                                ))
                                            }
                                        </div>
                                        {/* :
                                            <ListViewBlock
                                                data={folders}
                                                columns={columns}
                                                keyField="folder_id"
                                            />
                                        } */}
                                    </div>
                                ) : null}

                                {files.length > 0 && files.filter((f) => f.parent_id === folder_id).length > 0 ? (
                                    <div className="files tab-panel-custom pt-0">
                                        <div className="d-flex align-items-start justify-content-between mt-4">
                                            <h3 className="file-title folders__title">Files <span className='count-totals'>{files && files.length > 0 ? files.filter((f) => f.parent_id === folder_id).length : 0}</span></h3>
                                            <div className='btns-groups-grids'>
                                                <button className="btn-outline-primary" onClick={showFileToggle}>{filesCount > 6 ? 'Show Less' : 'Show all'}</button>
                                                <button type="button" className='bg-transparent border-0 ms-2' onClick={gridviewHandler}>
                                                    <img src={gridView ? ListIcon : MenuIcon} alt="views" />
                                                </button>
                                                {gridView && <Dropdown className="files-dropdown">
                                                    <Dropdown.Toggle id="dropdown-button-dark-example1">
                                                        <MdMoreVert />
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu className="custom-dropdown custom-sort-dropmenu">
                                                        {dropdownText && dropdownText.length > 0 ?
                                                            dropdownText.map((item, i) =>
                                                                <Dropdown.Item
                                                                    href="#/action-1"
                                                                    key={i}
                                                                    className={`${item.value === sortArray ? 'is_active' : ''}`}
                                                                    onClick={(e) => sortHandler(e, item)}
                                                                >
                                                                    {item.name}
                                                                </Dropdown.Item>
                                                            )
                                                            :
                                                            null
                                                        }
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                                }
                                            </div>
                                        </div>
                                        {gridView ?
                                            <Row ref={fileRef}>
                                                {filesArray.filter((f) => f.parent_id === folder_id).slice(0, filesCount).map((item, index) => (
                                                    <Col xs={12} sm={6} key={index}>
                                                        <FilesComponent
                                                            key={item.file_id}
                                                            handleFileContextMenu={(e) => handleFileContextMenu(e, item)}
                                                            id={index + 10}
                                                            file={item}
                                                            drop={drop}
                                                            allowDrop={allowDrop}
                                                            dragStart={dragStart}
                                                            onSelect={handlerSelectMultiple}
                                                            fileSelected={selectedFiles && selectedFiles.find(file => file.file_id === item.file_id) ? true : false}
                                                        />
                                                    </Col>
                                                ))}
                                            </Row>
                                            :
                                            <ListViewBlock
                                                data={files.filter((f) => f.parent_id === folder_id).slice(0, filesCount)}
                                                columns={columns}
                                                keyField="file_id"
                                                rowEvents={rowEvents}
                                                selectRow={selectRow}
                                            />
                                        }
                                    </div>
                                ) : null}

                                <LinkFilesMinimize />

                            </div>
                            :
                            null
                    }
                </Scrollbars>
            </section>
            {!searched && <hr className="hr-verticle" />
            }
            {
                match.url.split('/')[2] !== 'folder-detail' && !searched &&
                !searched && <>
                    <div onClick={() => { layout.isActivitiesSidebar && dispatch(activitiesBarToggle()); }} className={`overlay-wrap ${layout.isMobileView && layout.isActivitiesSidebar ? 'is_active' : ''}`}></div>
                    <section className={`right-side-section ${layout.isMobileView ? 'is-mobile' : ""} ${layout.isActivitiesSidebar ? 'is_open' : ''}`}>
                        <Scrollbars
                            renderView={scrollbar.renderView}
                            renderTrackHorizontal={scrollbar.renderTrackHorizontal}
                            renderTrackVertical={scrollbar.renderTrackVertical}
                            renderThumbHorizontal={scrollbar.renderThumb}
                            renderThumbVertical={scrollbar.renderThumb}
                            universal={true}
                        >
                            <Activites />
                            {/* <ChatModule /> */}
                        </Scrollbars>
                    </section>
                </>
            }
            <MovePopup />
            <UploadPopup folder_id={params.id} />
            <CreateFolderPopup />
            <FileContextMenu />
            <FolderContextMenu />
            <RenamePopup />
            <TagPopup />
            <DetailPopup />
            <CheckoutPopup />
            <VersionPopup />
            <CheckinPopup
                dropHandler={dropHandler}
                dragOverHandler={dragOverHandler}
            />
            <FileProperties />
            <FolderAreaContextMenu />
            <LinkFiles />
            <SkyLight
                hideOnOverlayClicked
                ref={pwdPopup}
                dialogStyles={pwdPopupStyle}
            >
                <div className="w-100" style={{ paddingTop: '0.5em' }}>
                    Your password will expire in {userInfo.pwd_validity_period}{' '}
                    days.&nbsp;
                    <span
                        style={{
                            color: '#534cdd',
                            cursor: 'pointer',
                        }}
                        onClick={() => {
                            pwdPopup.current.hide();
                            setTimeout(() => {
                                history.push('/user');
                            }, 100);
                        }}
                    >
                        Change your password
                    </span>
                    &nbsp; now.
                </div>
            </SkyLight>
            <PinBar />
        </div>
    )
}

Files.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.object.isRequired,
        url: PropTypes.string.isRequired,
    }).isRequired,
};

export default withRouter(Files);