import React, { useRef, useEffect, useState } from 'react';
import axios from 'axios';
import SkyLight from 'react-skylight';
import PropTypes from 'prop-types';
import { BsPauseCircle } from 'react-icons/bs'
import { MdClear } from 'react-icons/md'
import { emitter } from '../../utils/emitter';
import getDefaultThumbnail from '../../utils/getDefaultThumbnail';
import {
  fixPercent,
  bytesToSize,
  truncate,
  avgPercent,
  uploadTotalLoaded,
  uploadTotalSize,
  checkUploadTypeByFileName,
} from '../../utils/string';
import { HOME_EVENTS, POPUP_EVENTS } from '../../constants/events';
import { notifySuccess, notifyError } from '../common/Alert';
import CONFIG from '../../constants/config';
import CustomLiquidFillGauge from '../common/customLiquidFillGauge';
import { useDispatch, useSelector } from 'react-redux';
import { checkDocs } from '../../redux/actions/documents';
import { checkQuickAccess } from '../../redux/actions/quickAccess';
import { checkInSuccess } from '../../redux/actions/files';
import { getUsageSuccess } from '../../redux/actions/usage';
import hashFile from '../../utils/hashFile';

export default function UploadPopup({ folder_id }) {
  const popUpRef = useRef(true);
  const usage = useSelector(s => s.usage)
  const [uploadFiles, setUploadFiles] = useState([]);
  const [userUsage, setUserUsage] = useState(usage);

  const popUpStyle = {
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    minHeight: '0',
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    // backgroundColor: '#F6F7FB',
    marginTop: 0,
    marginLeft: 0,
    zIndex: 101,
    overflow : 'hidden',
    overflowY : 'auto'
  };
  const dispatch = useDispatch();
  function shopPopupListener() {
    if (popUpRef.current) popUpRef.current.show();
  }
  function hidePopupListener() {
    popUpRef.current.hide();
  }
  function showSmallPopup() {
    setUploadFiles([]);
    hidePopupListener();
    if (document.getElementById('uploadfile')) {
      document.getElementById('uploadfile').value = '';
    }
    if (document.getElementById('uploadfiles')) {
      document.getElementById('uploadfiles').value = '';
    }
  }
  async function dropUploadFile(file, index) {
    console.log("file*/*/*/",file);
    uploadFiles[index] = {
      name: file.name,
      size: file.size,
      percent: 0,
      loaded: 0,
      status: '',
    };
    setUploadFiles([...uploadFiles]);
    if (!folder_id) {
      const data = await axios.get(
        `${CONFIG.BASE_URL}/file_upload_link?originalname=${encodeURIComponent(
          file.name
        )}&type=${file.type}`,
        { withCredentials: true }
      );
      const { upload_url, filename } = data.data;
      axios
        .put(upload_url, file, {
          headers: {
            'Content-Type': file.type,
          },
          onUploadProgress: ({ loaded, total }) => {
            uploadFiles[index] = {
              ...uploadFiles[index],
              percent: fixPercent((loaded / total) * 100),
              loaded,
            };
            setUploadFiles([...uploadFiles]);
          },
        })
        .then(() =>
          hashFile(file, (hash_code) => {
            axios
              .post(
                `${CONFIG.BASE_URL}/file/uploaded_file_detail`,
                {
                  file_name: filename,
                  hash_code,
                },
                { withCredentials: true }
              )
              .then((response) => {

                uploadFiles[index] = {
                  ...uploadFiles[index],
                  status: 'success',
                };
                if (response.success) {
                  const totalUsage = userUsage.storage_used;
                  const filesAray = response.files;
                  for (let f = 0; f < filesAray.length; f++) {
                    totalUsage = totalUsage + filesAray[f].size
                  }

                  setUserUsage({ ...userUsage, storage_used: totalUsage })
                }
                setUploadFiles([...uploadFiles]);
                dispatch(checkDocs(null));
                dispatch(checkQuickAccess());
                dispatch(getUsageSuccess(userUsage))
                notifySuccess();
              })
              .catch((err) => {
                uploadFiles[index] = {
                  ...uploadFiles[index],
                  status: 'failed',
                };
                setUploadFiles([...uploadFiles]);
                console.log(err);
              });
          })
        );
    } else {
      const data = await axios.get(
        `${CONFIG.BASE_URL}/file_upload_link?originalname=${encodeURIComponent(
          file.name
        )}&type=${file.type}&parent_id=${folder_id}`,
        { withCredentials: true }
      );
      const { upload_url, filename, parent_id } = data.data;

      axios
        .put(upload_url, file, {
          headers: {
            'Content-Type': file.type,
          },
          onUploadProgress: ({ loaded, total }) => {
            uploadFiles[index] = {
              ...uploadFiles[index],
              percent: fixPercent((loaded / total) * 100),
              loaded,
            };
            setUploadFiles([...uploadFiles]);
          },
        })
        .then(() =>
          hashFile(file, (hash_code) => {
            axios
              .post(
                `${CONFIG.BASE_URL}/file/uploaded_file_detail`,
                {
                  file_name: filename,
                  parent_id,
                  hash_code,
                },
                { withCredentials: true }
              )
              .then((response) => {
                uploadFiles[index] = {
                  ...uploadFiles[index],
                  status: 'success',
                };

                if (response.success) {
                  const totalUsage = userUsage.storage_used;
                  const filesAray = response.files;
                  for (let f = 0; f < filesAray.length; f++) {
                    totalUsage = totalUsage + filesAray[f].size
                  }

                  setUserUsage({ ...userUsage, storage_used: totalUsage })
                }

                setUploadFiles([...uploadFiles]);
                dispatch(checkDocs(parent_id));
                dispatch(getUsageSuccess(userUsage))
                notifySuccess();
              })
              .catch((err) => {
                uploadFiles[index] = {
                  ...uploadFiles[index],
                  status: 'failed',
                };
                setUploadFiles([...uploadFiles]);
                console.log(err);
              });
          })
        );
    }
  }
  function dropHandlerListener(ev) {
    // console.log('File(s) dropped');
    // console.log('ev2', ev);
    ev.preventDefault();

    if (ev.dataTransfer.items) {
      let flag = 1;
      for (let i = 0; i < ev.dataTransfer.items.length; i++) {
        console.log(ev.dataTransfer.items[i].getAsFile());
        if (ev.dataTransfer.items[i].kind === 'file') {

          const file = ev.dataTransfer.items[i].getAsFile();
          if (checkUploadTypeByFileName(file.name)) {
            dropUploadFile(file, i)

          } else {
            notifyError(`${file.name} is not supported for upload.`);
          }
        }
      }
    } else {

      for (let i = 0; i < ev.dataTransfer.files.length; i++) {
        const file = ev.dataTransfer.files[i];
        console.log(file)
        if (checkUploadTypeByFileName(file.name)) {
          dropUploadFile(file, i);

        } else {
          notifyError(`${file.name} is not supported for upload.`);
        }
      }
    }
    // console.log(uploadFiles);
    if (uploadFiles.length) {
      shopPopupListener();
    }
  }
  function renderUploadInfo(file) {
    if (file.status === 'success') {
      if (
        uploadFiles.filter(
          (item) => item.status === 'success' || item.status === 'unsupported'
        ).length === uploadFiles.length
      ) {
        setTimeout(() => showSmallPopup(), 1000);
      }
      return (
        <>
          <div className="file-info">
            100% <strong>&#183;</strong>
            {bytesToSize(file.size)}
          </div>
          <div className="file-status text-success">
            Upload successful{' '}
            <i className="text-success fa fa-check-circle-o" />
          </div>
        </>
      );
    }
    if (file.status === 'failed') {
      return (
        <>
          <div className="file-info text-danger">Failed</div>
          <div className="file-status">
            <button type="button" className="btn btn-danger btn-sm">
              <i className="fa fa-undo" /> Try again
            </button>
          </div>
        </>
      );
    }
    return (
      <>
        <div className="file-info">
          {fixPercent(file.percent)}% <strong>&#183;</strong>{' '}
          {bytesToSize(file.size)}
        </div>
        <div className="file-status">
          <button type="button">
            <i className="ic-upload-pause" />
          </button>
          <button type="button">
            <i className="ic-upload-cancel" />
          </button>
        </div>
      </>
    );
  }
  async function handleUploadFileListener(e) {
    const [file] = e.target.files;
    if (checkUploadTypeByFileName(file.name)) {
      uploadFiles[0] = {
        name: file.name,
        size: file.size,
        percent: 0,
        loaded: 0,
      };
      setUploadFiles([...uploadFiles]);
      shopPopupListener();
      emitter.emit(HOME_EVENTS.TOGGLE_SHOW_UPLOAD_OPTIONS, false);
      if (!folder_id) {
        const data = await axios.get(
          `${CONFIG.BASE_URL
          }/file_upload_link?originalname=${encodeURIComponent(
            file.name
          )}&type=${file.type}`,
          { withCredentials: true }
        );
        const { upload_url, filename } = data.data;
        // console.log("filename",filename);
        // console.log("88888");
        axios
          .put(upload_url, file, {
            headers: {
              'Content-Type': file.type,
            },
            onUploadProgress: ({ loaded, total }) => {
              uploadFiles[0] = {
                ...uploadFiles[0],
                percent: fixPercent((loaded / total) * 100),
                loaded,
              };
              setUploadFiles([...uploadFiles]);
            },
          })
          .then(() =>
            hashFile(file, (hash_codes) => {
              console.log("hash_codes",hash_codes, file);

              axios
                .post(
                  `${CONFIG.BASE_URL}/file/uploaded_file_detail`,
                  {
                    file_name: filename,
                    hash_codes,
                  },
                  { withCredentials: true }
                )
                .then(() => {
                  uploadFiles[0] = {
                    ...uploadFiles[0],
                    status: 'success',
                  };
                  setUploadFiles([...uploadFiles]);
                  dispatch(checkDocs(null));
                  dispatch(checkQuickAccess());
                })
                .catch((err) => {
                  uploadFiles[0] = {
                    ...uploadFiles[0],
                    status: 'failed',
                  };
                  setUploadFiles([...uploadFiles]);
                  console.log("err02121121",err);
                });
            })
          );
      } else {
        const data = await axios.get(
          `${CONFIG.BASE_URL
          }/file_upload_link?originalname=${encodeURIComponent(
            file.name
          )}&type=${file.type}&parent_id=${folder_id}`,
          { withCredentials: true }
        );
        const { upload_url, filename, parent_id } = data.data;
        axios
          .put(upload_url, file, {
            headers: {
              'Content-Type': file.type,
            },
            onUploadProgress: ({ loaded, total }) => {
              uploadFiles[0] = {
                ...uploadFiles[0],
                percent: fixPercent((loaded / total) * 100),
                loaded,
              };
              setUploadFiles([...uploadFiles]);
            },
          })
          .then(() =>
            hashFile(file, (hash_code) => {
              axios
                .post(
                  `${CONFIG.BASE_URL}/file/uploaded_file_detail`,
                  {
                    file_name: filename,
                    parent_id,
                    hash_code,
                  },
                  { withCredentials: true }
                )
                .then(() => {
                  uploadFiles[0] = {
                    ...uploadFiles[0],
                    status: 'success',
                  };
                  setUploadFiles([...uploadFiles]);
                  dispatch(checkDocs(parent_id));
                  // dispatch(checkQuickAccess());
                })
                .catch((err) => {
                  uploadFiles[0] = {
                    ...uploadFiles[0],
                    status: 'failed',
                  };
                  setUploadFiles([...uploadFiles]);
                  console.log(err);
                });
            })
          );
      }
    } else {
      notifyError(`${file.name} is not supported for upload.`);
      emitter.emit(HOME_EVENTS.TOGGLE_SHOW_UPLOAD_OPTIONS, false);
    }
  }
  async function handleCheckinFileListener(
    file,
    fileId,
    parentId,
    note,
    callback
  ) {
    if (checkUploadTypeByFileName(file.name)) {
      uploadFiles[0] = {
        name: file.name,
        size: file.size,
        percent: 0,
        loaded: 0,
      };
      setUploadFiles([...uploadFiles]);
      shopPopupListener();
      emitter.emit(HOME_EVENTS.TOGGLE_SHOW_UPLOAD_OPTIONS, false);
      let url = `${CONFIG.BASE_URL
        }/file_upload_link?originalname=${encodeURIComponent(file.name)}&type=${file.type
        }`;
      if (parentId) {
        url += `&parent_id=${parentId}`;
      }
      const response = await axios({
        method: 'GET',
        url,
        withCredentials: true,
      });
      const { upload_url, filename } = response.data;
      axios
        .put(upload_url, file, {
          headers: {
            'Content-Type': file.type,
          },
          onUploadProgress: ({ loaded, total }) => {
            uploadFiles[0] = {
              ...uploadFiles[0],
              percent: fixPercent((loaded / total) * 100),
              loaded,
            };
            setUploadFiles([...uploadFiles]);
          },
        })
        .then(() =>
          hashFile(file, (hash_code) => {
            axios
              .post(
                `${CONFIG.BASE_URL}/file/checkin-file-detail/${fileId}`,
                {
                  file_name: filename,
                  note,
                  hash_code,
                },
                { withCredentials: true }
              )
              .then(() => {
                uploadFiles[0] = {
                  ...uploadFiles[0],
                  status: 'success',
                };
                setUploadFiles([...uploadFiles]);
                dispatch(checkInSuccess(fileId));
                dispatch(checkDocs(null));
                dispatch(checkQuickAccess());
                callback();
              })
              .catch((err) => {
                uploadFiles[0] = {
                  ...uploadFiles[0],
                  status: 'failed',
                };
                setUploadFiles([...uploadFiles]);
                console.log(err);
              });
          })
        )
        .catch((err) => notifyError(err));
    } else {
      notifyError(`${file.name} is not supported for upload.`);
      emitter.emit(HOME_EVENTS.TOGGLE_SHOW_UPLOAD_OPTIONS, false);
    }
  }
  function handleUploadFilesListener(e) {
    const { files } = e.target;
    for (const index in Object.values(files)) {
      if (checkUploadTypeByFileName(files[index].name)) {
        uploadFiles[index] = {
          name: files[index].name,
          size: files[index].size,
          percent: 0,
          loaded: 0,
        };
        setUploadFiles([...uploadFiles]);
        if (!folder_id) {
          axios
            .get(
              `${CONFIG.BASE_URL
              }/file_upload_link?originalname=${encodeURIComponent(
                files[index].name
              )}&type=${files[index].type}`,
              { withCredentials: true }
            )
            .then(({ data: { upload_url, filename } }) => {
              axios
                .put(upload_url, files[index], {
                  headers: {
                    'Content-Type': files[index].type,
                  },
                  onUploadProgress: ({ loaded, total }) => {
                    uploadFiles[index] = {
                      ...uploadFiles[index],
                      percent: fixPercent((loaded / total) * 100),
                      loaded,
                    };
                    setUploadFiles([...uploadFiles]);
                  },
                })
                .then(() =>
                  hashFile(files[index], (hash_code) => {
                    axios
                      .post(
                        `${CONFIG.BASE_URL}/file/uploaded_file_detail`,
                        {
                          file_name: filename,
                          hash_code,
                        },
                        { withCredentials: true }
                      )
                      .then(() => {
                        uploadFiles[index] = {
                          ...uploadFiles[index],
                          status: 'success',
                        };
                        setUploadFiles([...uploadFiles]);
                        dispatch(checkDocs(null));
                        dispatch(checkQuickAccess());
                      })
                      .catch((err) => {
                        uploadFiles[index] = {
                          ...uploadFiles[index],
                          status: 'failed',
                        };
                        setUploadFiles([...uploadFiles]);
                        console.log(err);
                      });
                  })
                );
            });
        } else {
          axios
            .get(
              `${CONFIG.BASE_URL
              }/file_upload_link?originalname=${encodeURIComponent(
                files[index].name
              )}&type=${files[index].type}&parent_id=${folder_id}`,
              { withCredentials: true }
            )
            .then(({ data: { upload_url, filename, parent_id } }) => {
              axios
                .put(upload_url, files[index], {
                  headers: {
                    'Content-Type': files[index].type,
                  },
                  onUploadProgress: ({ loaded, total }) => {
                    uploadFiles[index] = {
                      ...uploadFiles[index],
                      percent: fixPercent((loaded / total) * 100),
                      loaded,
                    };
                    setUploadFiles([...uploadFiles]);
                  },
                })
                .then(() =>
                  hashFile(files[index], (hash_code) => {
                    axios
                      .post(
                        `${CONFIG.BASE_URL}/file/uploaded_file_detail`,
                        {
                          file_name: filename,
                          parent_id,
                          hash_code,
                        },
                        { withCredentials: true }
                      )
                      .then(() => {
                        uploadFiles[index] = {
                          ...uploadFiles[index],
                          status: 'success',
                        };
                        setUploadFiles([...uploadFiles]);
                        dispatch(checkDocs(parent_id));
                      })
                      .catch((err) => {
                        uploadFiles[index] = {
                          ...uploadFiles[index],
                          status: 'failed',
                        };
                        setUploadFiles([...uploadFiles]);
                        console.log(err);
                      });
                  })
                );
            });
        }
      } else {
        uploadFiles[index] = {
          name: files[index].name,
          size: 0,
          percent: 0,
          loaded: 0,
          status: 'unsupported',
        };
        setUploadFiles([...uploadFiles]);
        notifyError(`${files[index].name} is not supported for upload.`);
        emitter.emit(HOME_EVENTS.TOGGLE_SHOW_UPLOAD_OPTIONS, false);
      }
    }
    if (uploadFiles.length) {
      shopPopupListener();
      emitter.emit(HOME_EVENTS.TOGGLE_SHOW_UPLOAD_OPTIONS, false);
    }
  }

  useEffect(() => {
    function listenToTheEmitter() {
      emitter.on(POPUP_EVENTS.SHOW_UPLOAD_POPUP, shopPopupListener);
      emitter.on(POPUP_EVENTS.HIDE_UPLOAD_POPUP, hidePopupListener);
      emitter.on(HOME_EVENTS.DROP_FILE_OR_FOLDER, dropHandlerListener);
      emitter.on(HOME_EVENTS.HANLDE_UPLOAD_FILE, handleUploadFileListener);
      emitter.on(HOME_EVENTS.HANLDE_CHECKIN_FILE, handleCheckinFileListener);
      emitter.on(HOME_EVENTS.HANLDE_UPLOAD_FILES, handleUploadFilesListener);
    }
    // popUpRef.current.show();
    listenToTheEmitter();
    return () => {
      emitter.off(POPUP_EVENTS.SHOW_UPLOAD_POPUP, shopPopupListener);
      emitter.off(POPUP_EVENTS.HIDE_UPLOAD_POPUP, hidePopupListener);
      emitter.off(HOME_EVENTS.DROP_FILE_OR_FOLDER, dropHandlerListener);
      emitter.off(HOME_EVENTS.HANLDE_UPLOAD_FILE, handleUploadFileListener);
      emitter.off(HOME_EVENTS.HANLDE_CHECKIN_FILE, handleCheckinFileListener);
      emitter.off(HOME_EVENTS.HANLDE_UPLOAD_FILES, handleUploadFilesListener);
    };
  }, [handleUploadFileListener]);
  return (
    <SkyLight hideOnOverlayClicked ref={popUpRef} dialogStyles={popUpStyle}>
      <div className="uploading">
        <div className="uploading__process d-flex">
          <div className="donut-chart">
            <CustomLiquidFillGauge
              radius={60}
              value={Number(avgPercent(uploadFiles.filter((file) => file)))}
            />
          </div>
          <div className="info">
            <div>Upload proccess</div>
            <div>
              Uploading{' '}
              {
                uploadFiles.filter((file) => file.status !== 'unsupported')
                  .length
              }{' '}
              files...
            </div>
            <div>
              {Number(avgPercent(uploadFiles.filter((file) => file)))}%
              <strong>&#183;</strong>
              {bytesToSize(
                Number(uploadTotalLoaded(uploadFiles.filter((file) => file)))
              )}
              /
              {bytesToSize(
                Number(uploadTotalSize(uploadFiles.filter((file) => file)))
              )}
            </div>
          </div>
          <div className="button-list">
            <button type="button" className="">
              <i className="fa fa-pause-circle-o"><BsPauseCircle /></i> Pause All
            </button>
            <button type="button">
              <i className="fa fa-times"><MdClear /></i> Cancel All
            </button>
          </div>
        </div>
        <hr />
        <div className="uploading__files">
          {/* <div className="file d-flex">
        <div className="file-name d-flex"><i className="ic-file-png"/>stickersheet-components</div>
        <div className="progress-bar">
          <span className="progress-bar-fill" style={{ width: '100%' }}/>
        </div>
        <div className="file-info">100% <strong>&#183;</strong> 220KB</div>
        <div className="file-status text-success">
          Upload successful <i className="text-success fa fa-check-circle-o"/>
        </div>
      </div>
      <div className="file d-flex">
        <div className="file-name d-flex"><i className="ic-file-png"/>stickersheet-components</div>
        <div className="progress-bar">
          <span className="progress-bar-fill" style={{ width: '70%' }}/>
        </div>
        <div className="file-info">100% <strong>&#183;</strong> 220KB</div>
        <div className="file-status">
          <a><i className="ic-upload-pause"/>&nbsp;<i className="ic-upload-pause"/></a>
          <a><i className="ic-upload-cancel"/></a>
        </div>
      </div>
      <div className="file d-flex">
        <div className="file-name d-flex"><i className="ic-file-png"/>stickersheet-components</div>
        <div className="progress-bar">
          <span className="progress-bar-fill" style={{ width: '70%' }}/>
        </div>
        <div className="file-info">100% <strong>&#183;</strong> 220KB <strong>&#183;</strong> 20 seconds left</div>
        <div className="file-status">
          <a><i className="ic-upload-pause"/>&nbsp;<i className="ic-upload-pause"/></a>
          <a><i className="ic-upload-cancel"/></a>
        </div>
      </div>
      <div className="file d-flex">
        <div className="file-name d-flex"><i className="ic-file-png"/>stickersheet-components</div>
        <div className="progress-bar">
          <span className="progress-bar-fill" style={{ width: '0%' }}/>
        </div>
        <div className="file-info text-danger">Failed</div>
        <div className="file-status">
          <button type="button" className="btn btn-danger btn-sm"><i className="fa fa-undo"/> Try again</button>
        </div>
      </div> */}
          {uploadFiles.map(
            (file) =>
              file &&
              file.status !== 'unsupported' && (
                <div className="file d-flex" key={`${file.id}`}>
                  <div className="file-name d-flex">
                    <i className="icon-file-png"><img src={getDefaultThumbnail('png')} alt="" /></i>
                    {truncate(file.name, 20)}
                  </div>
                  <div className="progress-bar">
                    <span
                      className="progress-bar-fill"
                      style={{ width: `${file.percent}%` }}
                    />
                  </div>
                  {renderUploadInfo(file)}
                  {/* <div className="file-info">{fixPercent(file.percent)}% <strong>&#183;</strong> {bytesToSize(file.size)}</div>
              <div className="file-status">
                <a><i className="ic-upload-pause"/>&nbsp;<i className="ic-upload-pause"/></a>
                <a><i className="ic-upload-cancel"/></a>
              </div> */}
                </div>
              )
          )}
        </div>
        <hr />
        <div className="uploading__footer">
          <button type="button">
            <i className="fa fa-plus" /> Pause All
          </button>
          <button type="button">
            <i className="fa fa-plus" /> Cancel All
          </button>
          {Number(avgPercent(uploadFiles.filter((file) => file))) === 100 ? (
            <button type="button" onClick={showSmallPopup}>
              Hide
            </button>
          ) :
            <button type="button">Uploading....</button>
          }
          {/* <a onClick={showSmallPopup}>Hide</a> */}
        </div>
      </div>
    </SkyLight>
  );
}
UploadPopup.propTypes = {
  folder_id: PropTypes.string,
};
UploadPopup.defaultProps = {
  folder_id: null,
};
