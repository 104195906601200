/* eslint-disable max-len */
// const BASE_URL = 'http://localhost:3000/api';
// const BASE_URL = 'https://i9ekkljod4.execute-api.ap-south-1.amazonaws.com/prod/api';
const BASE_URL = 'https://g6mgo2cffe.execute-api.ap-south-1.amazonaws.com/prod/api';
// const BASE_URL = 'https://aroc0rhzs8.execute-api.ap-south-1.amazonaws.com/prod/api';

const CONFIG = {
  BASE_URL,
  STRAPI_URL: 'https://cms.docquik.io',
  HOME_PAGE_URL: 'https://docquik.com',
  CLOUDFRONT_URL: 'https://d1mdlfh1k000su.cloudfront.net',

  // new client key
  DROPBOX_CLIENT_ID:'8wuonkavv603mh5', //live

  CALENDER_API_KEY: 'AIzaSyActYv_34DcFcaABT8Th0ISUgO9G7sDTp4',
  // MICROSOFT_ID_OAUTH:'ea00e8bc-1951-40dc-b83b-35cc0006b020',
  // MICROSOFT_ID_OAUTH: '32a0eddf-1e4f-4876-874c-16095a37314b',  //doc4
  MICROSOFT_ID_OAUTH: '7705cf77-5099-476f-b472-6ab803e5fa7e',  //new acc

  msalConfig: {
    auth: {
      clientId: "3b12891b-88e3-427a-b5a2-54fb3f4f196a",
      authority: "https://login.microsoftonline.com/d34d6ce4-63c5-4b48-8aa7-970104a5aa5e", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
      redirectUri: "http://localhost:3001",
    },
    cache: {
      cacheLocation: "localStorage",
      storeAuthStateInCookie: true
    }
  },
  //new client 
  GOOGLE_CLIENT_ID:"872125616752-hjbao6076ij83terjjvcct0rh971hg7l.apps.googleusercontent.com", 
  // GOOGLE_CLIENT_ID:"627416064670-9p0bj2a6v3p4cc6rgcbcujlmc791g4nh.apps.googleusercontent.com", //web3
  // GOOGLE_CLIENT_ID:"279112835922-mlmmcs3316a1ke205q01gqsa03isvd5i.apps.googleusercontent.com", //web4
  // GOOGLE_CLIENT_ID_AUTH:"170031471112-elpf7uua3t31oqi3saolkl2csq48gqnb.apps.googleusercontent.com",
  // GOOGLE_CLIENT_ID: "707432438223-ahiv52flb006cdutflrcbbs8o0p7vi4l.apps.googleusercontent.com", //working


  // CALENDER_CLIENT_ID: '593591983264-89ct7m98rjh1318vf7pn3v35fgbirhv6.apps.googleusercontent.com',

  //new client key
  CALENDER_CLIENT_ID: '627416064670-0bljni7qaebt3ijd04u02qghgr92fb7r.apps.googleusercontent.com',
  CALENDER_ID: 'en-gb.indian#holiday@group.v.calendar.google.com',

  // CALENDER_CLIENT_ID: '868503150682-fi6o3u0f3i13liko5luddf6mekp74kiu.apps.googleusercontent.com',
  // CALENDER_ID: '3rgimefv1b63bc51lrckogmhm4@group.calendar.google.com',
  DISCOVERY_DOCS: ["https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"],
  SCOPES: "https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/calendar.events",
  MIME_TYPES: {
    document: ['doc', 'docx', 'txt'],
    text: ['txt'],
    powerpoint: ['ppt', 'pptx', 'potx'],
    pdf: ['pdf', 'pdf/a'],
    image: ['png', 'jpg', 'jpeg'],
    excel: ['xls', 'xlsx', 'xlsb', 'xlsm', 'xltx'],
    zip: ['zip'],
  },
  ACCEPT_MIME_TYPES: {
    document: ['.doc', '.docx', '.txt'],
    text: ['.txt'],
    powerpoint: ['.ppt', '.pptx', '.potx'],
    pdf: ['.pdf', '.pdf/a'],
    image: ['.png', '.jpg', '.jpeg'],
    excel: ['.xls', '.xlsx', '.xlsb', '.xlsm', '.xltx'],
    zip: ['.zip'],
  },
  // default storage 15GB
  DEFAULT_STORAGE: 16106127360,
  GOOGLE_OAUTH: `https://accounts.google.com/o/oauth2/v2/auth?client_id=730125837586-b0v1c0m833q5cq68s65qbdjltvv654ov.apps.googleusercontent.com&response_type=code&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile%20openid&redirect_uri=${BASE_URL}/login-google`,
  MICROSOFT_OAUTH: `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=53b6ce26-f0ab-49c3-860e-ca704ebb29e6&response_type=code&redirect_uri=${BASE_URL}/login-microsoft&response_mode=query&scope=user.read`,
  YAHOO_OAUTH: `https://api.login.yahoo.com/oauth2/request_auth?response_type=code&redirect_uri=${BASE_URL}/login-yahoo&scope=profile%20email&state=W4383K8MTWc9BhUZ-zwvfexJ9P6GHSDD&client_id=dj0yJmk9ZTRSa2RQenp6Z2xMJmQ9WVdrOWFHRm1hMWhWVEU0bWNHbzlNQT09JnM9Y29uc3VtZXJzZWNyZXQmc3Y9MCZ4PTI1&nonce=W4383K8MTWc9BhUZ-zwvfexJ9P6GHSDD`,
};
export default CONFIG;
