import React, { useState, useEffect } from 'react';
import { Dropbox } from 'dropbox';
import { notifyError, notifySuccess } from './Alert';
import { uploadAuth } from '../../redux/actions/uploadAuth';
import { useDispatch, useSelector } from 'react-redux'
import CONFIG from '../../constants/config'

export default function DropboxAuth(props) {

    const { dropboxLogin, dropboxLoginError } = uploadAuth;
    const dispatch = useDispatch();
    const dropbox = useSelector((s) => s.uploadAuth.dropbox);

    const [isDropboxLoader, setIsDropboxLoader] = useState(false);
    const [isDropboxLogin, setIsDropboxLogin] = useState(false);
    const [dropboxUser, setDropboxUser] = useState(null);

    useEffect(() => {
        const tokenDropbox = localStorage.getItem('authDropbox')
        if (tokenDropbox) {
            setIsDropboxLogin(true)
            const dropbox_user = JSON.parse(tokenDropbox);
            setDropboxUser(dropbox_user);
            dispatch(dropboxLogin(dropbox_user))
        } else {
            setIsDropboxLogin(false)
            setDropboxUser(null);
            dispatch(dropboxLoginError())
        }
    }, [dropbox.isDropboxLogin])

    const authDropbox = () => {
        const dbx = new Dropbox({ clientId: CONFIG.DROPBOX_CLIENT_ID, clientSecret: 'xq6n37fk82oacmr' })
        // dbx.auth.getAuthenticationUrl('http://localhost:3001/callback')
        dbx.auth.getAuthenticationUrl('https://app.docquik.io/callback')
            .then((authUrl) => {
                window.location.href = authUrl;
                if(isDropboxLogin || dropbox.isDropboxLogin){
                    notifySuccess("DropBox connected Success!")
                };
            })
            .catch(err => {
                if (err) {
                    setIsDropboxLogin(false)
                    setDropboxUser(null);
                    dispatch(dropboxLoginError())
                }
            })
    }

    const renderDropboxButton = (type, className) => {
        let buttonText = "";
        switch (type) {
            default:
                buttonText = "Connect to Dropbox";
                break;
        }
        // console.log("isDropboxLogin",isDropboxLogin, dropbox.isDropboxLogin);
        if (isDropboxLogin || dropbox.isDropboxLogin) {
            return <button type="button" className={className} style={{color: '#FFF', background:'#28a745'}} >
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-dropbox" viewBox="0 0 16 16">
                    <path d="M8.01 4.555 4.005 7.11 8.01 9.665 4.005 12.22 0 9.651l4.005-2.555L0 4.555 4.005 2 8.01 4.555Zm-4.026 8.487 4.006-2.555 4.005 2.555-4.005 2.555-4.006-2.555Zm4.026-3.39 4.005-2.556L8.01 4.555 11.995 2 16 4.555 11.995 7.11 16 9.665l-4.005 2.555L8.01 9.651Z" />
                </svg>
                Dropbox Connected
                {/* <span className="not"></span> */}
                </button>
        }
        return (

            <button
                type="button"
                className={className}
                // onClick={saveWithGoogle}
                style={{color: '#FFF',background:'#dc3545'}} 
                onClick={authDropbox}
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-dropbox" viewBox="0 0 16 16">
                    <path d="M8.01 4.555 4.005 7.11 8.01 9.665 4.005 12.22 0 9.651l4.005-2.555L0 4.555 4.005 2 8.01 4.555Zm-4.026 8.487 4.006-2.555 4.005 2.555-4.005 2.555-4.006-2.555Zm4.026-3.39 4.005-2.556L8.01 4.555 11.995 2 16 4.555 11.995 7.11 16 9.665l-4.005 2.555L8.01 9.651Z" />
                </svg>
                {props.children ?
                    props.children
                    :
                    isDropboxLoader ?
                        'Loading...'
                        :
                        buttonText
                }
                {/* <span className="dot"></span> */}
            </button>
        )
    }

    return (
        <>{renderDropboxButton('', props.className)}</>
    );
}
