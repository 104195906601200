export const GOOGLEAUTH_CONNECTION_REQUEST = 'GOOGLEAUTH_CONNECTION_REQUEST';
export const GOOGLEAUTH_CONNECTION_SUCCESS = 'GOOGLEAUTH_CONNECTION_SUCCESS';
export const GOOGLEAUTH_CONNECTION_ERROR = 'GOOGLEAUTH_CONNECTION_ERROR';
export const DROPBOX_CONNECTION_REQUEST = 'DROPBOX_CONNECTION_REQUEST';
export const DROPBOX_CONNECTION_SUCCESS = 'DROPBOX_CONNECTION_SUCCESS';
export const DROPBOX_CONNECTION_ERROR = 'DROPBOX_CONNECTION_ERROR';
export const MICROSOFT_CONNECTION_REQUEST = 'MICROSOFT_CONNECTION_REQUEST';
export const MICROSOFT_CONNECTION_SUCCESS = 'MICROSOFT_CONNECTION_SUCCESS';
export const MICROSOFT_CONNECTION_ERROR = 'MICROSOFT_CONNECTION_ERROR';

export const uploadAuth = {
    googleLogin: (values) => ({ type: GOOGLEAUTH_CONNECTION_SUCCESS, payload:values }),
    googleLoginError: () => ({ type: GOOGLEAUTH_CONNECTION_ERROR }),
    dropboxLogin: (values) => ({ type: DROPBOX_CONNECTION_SUCCESS, payload:values }),
    dropboxLoginError: () => ({ type: DROPBOX_CONNECTION_ERROR }),
    microsoftLogin: (values) => ({ type: MICROSOFT_CONNECTION_SUCCESS, payload:values }),
    microsoftLoginError: () => ({ type: MICROSOFT_CONNECTION_ERROR }),
}