import React, { useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import { getPriviliges, updatePriviliges } from "../../../services/settings";
import { notifyError, notifySuccess } from "../../common/Alert";
import { Button } from "react-bootstrap";

export default function Privileges() {
  const [data, setData] = useState({});
  const handleSubmit = () => {
    updatePriviliges({
      share_document: data.share_document,
      receive_document: data.receive_document,
      publish: data.publish,
    })
      .then(() => notifySuccess())
      .catch((err) => notifyError(err?.response?.data?.data));
  };
  useEffect(() => {
    getPriviliges()
      .then(({ data: res }) => setData(res))
      .catch((err) => notifyError(err?.response?.data?.data));
  }, []);
  return (
    // <div className="bg-white p-4 mt-4" style={{ borderRadius: 10 }}>
    //   <div
    //     className="mb-2 pb-2"
    //     style={{ borderBottom: '1px solid rgba(0,0,0,.1)' }}
    //   >
    //     Share
    //   </div>
    //   <div className="row ai-center ml-3">
    //     <input
    //       type="checkbox"
    //       id="share_document"
    //       checked={data.share_document}
    //       onChange={() =>
    //         setData({ ...data, share_document: !data.share_document })
    //       }
    //     />
    //     <label className="mb-0 ml-3" htmlFor="share_document">
    //       Allow users to share documents outside this organization.
    //     </label>
    //   </div>
    //   <div className="row ai-center ml-3">
    //     <input
    //       type="checkbox"
    //       id="receive_document"
    //       checked={data.receive_document}
    //       onChange={() =>
    //         setData({ ...data, receive_document: !data.receive_document })
    //       }
    //     />
    //     <label className="mb-0 ml-3" htmlFor="receive_document">
    //       Allow users to receive documents outside this organization.
    //     </label>
    //   </div>
    //   <div
    //     className="mb-2 mt-5 pb-2"
    //     style={{ borderBottom: '1px solid rgba(0,0,0,.1)' }}
    //   >
    //     Publish
    //   </div>
    //   <div className="row ai-center ml-3">
    //     <input
    //       type="radio"
    //       name="publish"
    //       id="publish1"
    //       value="no"
    //       checked={data.publish === 'no'}
    //       onChange={(e) => setData({ ...data, publish: e.target.value })}
    //     />
    //     <label className="mb-0 ml-3" htmlFor="publish1">
    //       User cannot publish documents.
    //     </label>
    //   </div>
    //   <div className="row ai-center ml-3">
    //     <input
    //       type="radio"
    //       name="publish"
    //       id="publish2"
    //       value="origanization"
    //       checked={data.publish === 'origanization'}
    //       onChange={(e) => setData({ ...data, publish: e.target.value })}
    //     />
    //     <label className="mb-0 ml-3" htmlFor="publish2">
    //       Users can publish documents within the organization.
    //     </label>
    //   </div>
    //   <div className="row ai-center ml-3">
    //     <input
    //       type="radio"
    //       name="publish"
    //       id="publish3"
    //       value="world"
    //       checked={data.publish === 'world'}
    //       onChange={(e) => setData({ ...data, publish: e.target.value })}
    //     />
    //     <label className="mb-0 ml-3" htmlFor="publish3">
    //       Users can publish documents within the organization and to the
    //       external world.
    //     </label>
    //   </div>
    //   <br />
    //   <Button onClick={handleSubmit}>Save</Button>
    // </div>

    <>
      <Accordion className="setting-accordian mt-4">
        <Accordion.Item className="custom-item">
          <Accordion.Header className="c-header">Share</Accordion.Header>
          <Accordion.Body>
            <div className="custom-accordian">
              <input
                type="checkbox"
                id="share_document"
                checked="checked"
                checked={data.share_document}
                onChange={() =>
                  setData({ ...data, share_document: !data.share_document })
                }
              />

              <label className="accordian-label" htmlFor="share_document">
                Allow users to share documents outside this organization.
              </label>
            </div>
            <div>
              <input
                type="checkbox"
                id="receive_document"
                checked={data.receive_document}
                onChange={() =>
                  setData({ ...data, receive_document: !data.receive_document })
                }
              />
              <label className="accordian-label" htmlFor="receive_document">
                Allow users to receive documents outside this organization.
              </label>
            </div>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item className="custom-item">
          <Accordion.Header className="c-header">Publish</Accordion.Header>
          <Accordion.Body>
            <div>
              <input
                type="radio"
                name="publish"
                id="publish1"
                value="no"
                checked={data.publish === "no"}
                onChange={(e) => setData({ ...data, publish: e.target.value })}
              />
              <label className="accordian-label" htmlFor="publish1">
                User cannot publish documents.
              </label>
            </div>
            <div>
              <input
                type="radio"
                name="publish"
                id="publish2"
                value="origanization"
                checked={data.publish === "origanization"}
                onChange={(e) => setData({ ...data, publish: e.target.value })}
              />
              <label className="accordian-label" htmlFor="publish2">
                Users can publish documents within the organization.
              </label>
            </div>
            <div>
              <input
                type="radio"
                name="publish"
                id="publish3"
                value="world"
                checked={data.publish === "world"}
                onChange={(e) => setData({ ...data, publish: e.target.value })}
              />
              <label className="accordian-label" htmlFor="publish3">
                Users can publish documents within the organization and to the
                external world.
              </label>
            </div>
            <div className="d-flex align-items-center justify-content-end mt-5">
              {/* <button className="cancel-btn" onClick={() => setIsEdit(false)}>
                Cancel{" "}
              </button> */}
              <button className="change-btn" onClick={handleSubmit}>
                Save
              </button>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
}
