import * as ACTION_TYPES from '../actions/uploadAuth';

const initialState = {
    googleDrive: {
        isGoogleLoader: false,
        isGoogleLogin: false,
        googleUser: null,
    },
    dropbox:{
        isDropboxLoader: false,
        isDropboxLogin: false,
        dropboxUser: null,
    },
    microsoft:{
        isMicrosoftLoader: false,
        isMicrosoftLogin: false,
        microsoftUser: null,
    }
}

export default function uploadAuth(state = initialState, action) {
    switch (action.type) {
        case ACTION_TYPES.GOOGLEAUTH_CONNECTION_REQUEST:
            return {
                ...state,
                googleDrive: {
                    ...state.googleDrive,
                    isGoogleLoader: true
                }
            }
        case ACTION_TYPES.GOOGLEAUTH_CONNECTION_SUCCESS:
            return {
                ...state,
                googleDrive: {
                    ...state.googleDrive,
                    isGoogleLoader: false,
                    isGoogleLogin: true,
                    googleUser: action.payload
                }
            }
        case ACTION_TYPES.GOOGLEAUTH_CONNECTION_ERROR:
            return {
                ...state,
                googleDrive: {
                    ...state.googleDrive,
                    isGoogleLoader: false,
                    isGoogleLogin: false,
                    googleUser: null
                }
            }
        case ACTION_TYPES.DROPBOX_CONNECTION_REQUEST:
            return {
                ...state,
                dropbox: {
                    ...state.dropbox,
                    isDropboxLoader: true
                }
            }
        case ACTION_TYPES.DROPBOX_CONNECTION_SUCCESS:
            return {
                ...state,
                dropbox: {
                    ...state.dropbox,
                    isDropboxLoader: false,
                    isDropboxLogin: true,
                    dropboxUser: action.payload
                }
            }
        case ACTION_TYPES.DROPBOX_CONNECTION_ERROR:
            return {
                ...state,
                dropbox: {
                    ...state.dropbox,
                    isDropboxLoader: false,
                    isDropboxLogin: false,
                    dropboxUser: null
                }
            }
        case ACTION_TYPES.MICROSOFT_CONNECTION_REQUEST:
            return {
                ...state,
                microsoft: {
                    ...state.microsoft,
                    isMicrosoftLoader: true
                }
            }
        case ACTION_TYPES.MICROSOFT_CONNECTION_SUCCESS:
            return {
                ...state,
                microsoft: {
                    ...state.microsoft,
                    isMicrosoftLoader: false,
                    isMicrosoftLogin: true,
                    microsoftUser: action.payload
                }
            }
        case ACTION_TYPES.MICROSOFT_CONNECTION_ERROR:
            return {
                ...state,
                microsoft: {
                    ...state.microsoft,
                    isMicrosoftLoader: false,
                    isMicrosoftLogin: false,
                    MicrosoftUser: null
                }
            }
        default:
            return state;
    }
}