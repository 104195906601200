import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { openPinbar } from '../../redux/actions/pinbar';
import { Scrollbars } from 'react-custom-scrollbars';
import * as scrollbar from '../../helpers/scrollbar';
import moment from 'moment';
import axios from 'axios';
import CONFIG from '../../constants/config';
// import PropTypes from 'prop-types';
// import { bytesToSize } from '../../utils/string';
import { emitter } from '../../utils/emitter';
import { HOME_EVENTS } from '../../constants/events';

// import activityImg1 from '../../assets/images/activity-img1.svg'
// import activityImg2 from '../../assets/images/activity-img2.svg'
// import activityImg3 from '../../assets/images/activity-img3.svg'
// import activityImg4 from '../../assets/images/activity-img4.svg'

export default function Activites() {

    // const pinbar = useSelector(s => s.pinbar);

    const dispatch = useDispatch();

    const [activities, setActivities] = useState([]);
    // const [moreUploadOptions, setMoreUploadOptions] = useState(false);


    // function handleUploadFile(e) {
    //     emitter.emit(HOME_EVENTS.HANLDE_UPLOAD_FILE, e);
    // }

    // function handleUploadFiles(e) {
    //     emitter.emit(HOME_EVENTS.HANLDE_UPLOAD_FILES, e);
    // }

    // const usage = useSelector((s) => s.usage);

    // const propmtNewFolderName = () => {
    // showCreateFolderPopup();
    // };

    const mouseMove = (e) => {
        // var x = e.clientX;
        // var right = window.innerWidth - 10;
        // if (x > right) {
        // if (!pinbar.pinbarOpen) {
        dispatch(openPinbar())
        // }
        // }
    }

    useEffect(() => {
        function toggleShowUploadOptions(boolean) {
            console.log(boolean)
            // console.log(moreUploadOptions)
            // setMoreUploadOptions(boolean);
        }
        axios({
            url: `${CONFIG.BASE_URL}/activity_list`,
            withCredentials: true,
        })
            .then(({ data }) => setActivities(data.activities))
            .catch((e) => console.log(e));

        emitter.on(HOME_EVENTS.TOGGLE_SHOW_UPLOAD_OPTIONS, toggleShowUploadOptions);
        return () => {
            emitter.off(
                HOME_EVENTS.TOGGLE_SHOW_UPLOAD_OPTIONS,
                toggleShowUploadOptions
            );
        };
    }, []);

    return (
        <div className="home-activities-container" >
            {/* onMouseMove={mouseMove} */}
            <div className="topic-header">
                <h3 className="topic-hadding">Activity</h3>
                <button type="button" className="btn-secondary" onClick={mouseMove}>Show Analytics</button>
                {/* <button type="button" className="btn-outline-primary">Show all</button> */}
            </div>
            <div className="list-views">
                <h4 className="sub-topic-title">TODAY</h4>
                <ul className="viewlist-activities">
                    <Scrollbars
                        renderView={scrollbar.renderView}
                        renderTrackHorizontal={scrollbar.renderTrackHorizontal}
                        renderTrackVertical={scrollbar.renderTrackVertical}
                        renderThumbHorizontal={scrollbar.renderThumb}
                        renderThumbVertical={scrollbar.renderThumb}
                        universal={true}
                    >
                        {activities && activities.length > 0 ?
                            activities.map((item, i) =>
                                <li key={i}>
                                    {/* <div className="image-wraps">
                                        <img src={item.url} alt={item.name} />
                                    </div> */}
                                    <div className="content-wraps">
                                        <p>{item.acitivity_message}</p>
                                        <span>{moment(item.time_at).fromNow()}</span>
                                    </div>
                                </li>
                            )
                            :
                            null
                        }
                    </Scrollbars>
                </ul>
            </div>
        </div>
    )
}
