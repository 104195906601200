import axios from 'axios';
import CONFIG from '../constants/config';
import { checkUploadFiles } from '../redux/actions/uploadFiles';
import { fixPercent } from '../utils/string';

export const removeFile = async (action) => {
  const response = await axios({
    method: 'DELETE',
    url: `${CONFIG.BASE_URL}/file/${action.payload}`,
    withCredentials: true,
    data: {
      del_permanently: false,
    },
  });
  return response.data;
};

export const checkFile = async (action) => {
  const response = await axios({
    method: 'GET',
    url: `${CONFIG.BASE_URL}/checkout/${action.payload.fileId}`,
    withCredentials: true,
    params: { checkout: action.payload.isCheck ? 1 : 0 },
  });
  return response.data;
};

export const getUploadUrl = async (action) => {
  let url = `${CONFIG.BASE_URL
    }/file_upload_link?originalname=${encodeURIComponent(
      action.payload.file.name
    )}&type=${action.payload.file.type}`;
  if (action.payload.parentId) {
    url += `&parent_id=${action.payload.parentId}`;
  }
  const response = await axios({
    method: 'GET',
    url,
    withCredentials: true,
  });
  return response.data;
};

export const uploadFile = async (action) => {
  const response = await axios({
    method: 'PUT',
    url: action.payload.upload_url,
    headers: {
      'Content-Type': action.payload.file.type,
    },
    data: action.payload.file,
    onUploadProgress: ({ loaded, total }) => {
      checkUploadFiles({
        id: action.payload.fileId,
        percent: fixPercent((loaded / total) * 100),
        loaded,
      });
    },
  });
  return response.data;
};
export const uploadFileOnGDrive = async (data) => {
  const response = await axios({
    method: 'POST',
    url: `${CONFIG.BASE_URL}/googledriveupload`,
    withCredentials: true,
    // url: 'http://localhost:8000/api/drive/upload',
    data: data,
  });
  return response.data;
};
export const uploadFileOnDropbox = async (data) => {
  const response = await axios({
    method: 'POST',
    url: `${CONFIG.BASE_URL}/dropboxupload`,
    withCredentials: true,
    // url: 'http://localhost:8000/api/dropbox/upload',
    data: data,
  });
  return response.data;
};
export const uploadFileOnMicrosoft = async (data) => {
  const response = await axios({
    method: 'POST',
    url: `${CONFIG.BASE_URL}/onedriveupload`,
    withCredentials: true,
    // url: 'http://localhost:8000/api/dropbox/upload',
    data: data,
  });
  return response.data;
};

export const uploadFileDetail = async (action) => {
  const response = await axios({
    method: 'POST',
    url: `${CONFIG.BASE_URL}/file/uploaded_file_detail`,
    withCredentials: true,
    data: {
      file_name: action.payload.filename,
      parent_id: action.payload.parent_id,
    },
  });
  return response.data;
};

export const uploadCheckinDetail = async (action) => {
  const response = await axios({
    method: 'POST',
    url: `${CONFIG.BASE_URL}/file/checkin-file-detail/${action.payload.fileId}`,
    withCredentials: true,
    data: {
      file_name: action.payload.filename,
      note: action.payload.note,
    },
  });
  return response.data;
};

export const pasteFile = async (action) => {
  const response = await axios({
    method: 'POST',
    url: `${CONFIG.BASE_URL}/copy-file`,
    withCredentials: true,
    data: {
      file_id: action.payload.fileId,
      folder_id: action.payload.folderId ? action.payload.folderId : 0,
    },
  });
  return response.data;
};

export const linkFile = async (action) => {
  const { file_id, file_to_link } = action.payload;
  const response = await axios({
    url: `${CONFIG.BASE_URL}/link-file`,
    method: 'POST',
    withCredentials: true,
    data: {
      file_id,
      file_id_linked: file_to_link.file_id,
    },
  });
  return response.data;
};

export const deleteLink = async (action) => {
  const response = await axios({
    url: `${CONFIG.BASE_URL}/link-file`,
    method: 'DELETE',
    withCredentials: true,
    data: {
      file_id: action.payload.file_id,
      file_id_linked: action.payload.file_id_to_delete,
    },
  });
  return response.data;
};

export const getLinkedFiles = async (action) => {
  const response = await axios({
    url: `${CONFIG.BASE_URL}/link-file/${action.payload}`,
    method: 'GET',
    withCredentials: true,
  });
  return response.data;
};
