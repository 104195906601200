import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { getFileName } from '../utils/string';
import { setItemLocalStorage } from '../utils/localStorage';
import CONFIG from '../constants/config';
import { emitter } from '../utils/emitter';
import { POPUP_EVENTS } from '../constants/events';
import noFile from '../assets/images/nofile.png';
import FileDetailSideBar from '../components/FileDetailSideBar';
import LinkFiles from '../components/Home/LinkFiles';
import { MdKeyboardBackspace } from 'react-icons/md';
import { BsLink45Deg, BsDownload, BsInfo, BsShareFill } from 'react-icons/bs';
import getDefaultThumbnail from '../utils/getDefaultThumbnail';
import FileSaver from 'file-saver';
import { checkLinkedFiles } from '../redux/actions/linkFilePopup';
import ReactTooltip from 'react-tooltip';
import { Scrollbars } from 'react-custom-scrollbars';
import * as scrollbar from '../helpers/scrollbar';
import { notifyError } from '../components/common/Alert';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { DocumentEditorComponent } from '@syncfusion/ej2-react-documenteditor';
import { DocumentEditorContainerComponent, Toolbar } from '@syncfusion/ej2-react-documenteditor';
import PdfViewerComponent from '../components/PdfViewerComponent';

DocumentEditorComponent.Inject(Toolbar);
DocumentEditorContainerComponent.Inject(Toolbar);
export default function FileDetail({ match,...props }) {
    const showSignatureOption = props?.location?.state?.showSignature

    const [loading, setLoading] = useState(false);
    const [msg, setMsg] = useState('');
    const [folderId, setFolderId] = useState(null);
    const [fileSrc, setFileSrc] = useState('');
    const [fileName, setFileName] = useState('');
    const [orignalFileName, setOrignalFileName] = useState('');
    const [fileType, setFileType] = useState('');
    const [links, setLinks] = useState([]);
    const [initialComments, setInitialComments] = useState([]);
    const history = useHistory();
    const dispatch = useDispatch();
    const goBack = () => history.goBack();

    const fileId = match.params.id;
    console.log("Files :",fileSrc);
    console.log("fileType :",fileType);
    const renderView = () => {
        if (!loading) {
            if (fileSrc) {
                if (CONFIG.MIME_TYPES.excel.includes(fileType)) {
                    return (
                        <div className="pg-viewer-wrapper">
                            <iframe
                                title="excel"
                                key={Date.now()}
                                src={fileSrc}
                                style={{ width: '100%', height: '100%' }}
                                frameBorder="0"
                            />
                        </div>
                    );
                } else if (CONFIG.MIME_TYPES.image.includes(fileType)) {
                    setItemLocalStorage('imgSrc', fileSrc);
                    return (
                        <div className="pg-viewer-wrapper">
                            <iframe
                                title="img"
                                key={Date.now()}
                                src="/previewImage"
                                style={{ width: '100%', height: '100%' }}
                                frameBorder="0"
                            />
                        </div>
                    );
                } else if (CONFIG.MIME_TYPES.pdf.includes(fileType)) {
                    console.log("Else Type :",fileType);
                    return (<PdfViewerComponent showSignatureOption={showSignatureOption} document={fileSrc} />)
                }
                return (
                    <div className="pg-viewer-wrapper">
                        <iframe
                            title="another"
                            key={Date.now()}
                            src={`${fileSrc}`}
                            style={{ width: '100%', height: '100%' }}
                            frameBorder="0"
                        />
                    </div>
                );
            } else {
                return (
                    <div className="pg-viewer-wrapper no-file text-center">
                        <img src={noFile} alt="" />
                        <p>{msg !== '' ? msg : 'Your file is not loading'}</p>
                        <button type='button' className='btn-uploads mt-5' onClick={goBack}> <span style={{ fontSize: '20px', lineHeight: '0' }}><MdKeyboardBackspace /></span>Go Back</button>
                    </div>
                );
            }
        } else {
            return (
                <div className="pg-viewer-wrapper no-file text-center">
                    <img src={noFile} alt="" />
                    <p>Your file is loading...</p>
                </div>
            );
        }

    };

    useEffect(() => {
        async function getDocById() {
            let res = {};
            try {
                setMsg('')
                setLoading(true)
                res = await axios.get(`${CONFIG.BASE_URL}/file/${match.params.id}`, {
                    withCredentials: true,
                });
                if (res.data.success) {
                    const {
                        type,
                        path,
                        new_name,
                        file_name,
                        comments,
                        parent_id,
                        links: linksArray
                    } = res.data.document;
                    const responseFileType = type;
                    const responseFileSrc = path;
                    const responseFileName = new_name ? new_name : getFileName(file_name);
                    const setName = file_name;
                    setMsg('')
                    setFolderId(parent_id);
                    setOrignalFileName(file_name)
                    setLoading(false)
                    if (
                        CONFIG.MIME_TYPES.excel.includes(responseFileType) ||
                        CONFIG.MIME_TYPES.powerpoint.includes(responseFileType) ||
                        CONFIG.MIME_TYPES.document.includes(responseFileType)
                    ) {
                        setFileSrc(
                            `https://view.officeapps.live.com/op/embed.aspx?src=${responseFileSrc}`
                        );
                        setFileType(responseFileType);
                    } else if (
                        CONFIG.MIME_TYPES.text.includes(responseFileType) ||
                        CONFIG.MIME_TYPES.text.includes(responseFileType)
                    ) {
                        setFileSrc(
                            `https://docs.google.com/gview?url=${responseFileSrc}&embedded=true`
                        );
                        setFileType(responseFileType);
                    } else {
                        setFileSrc(responseFileSrc);
                        setFileType(responseFileType);
                    }
                    setInitialComments(comments);
                    setLinks(linksArray);
                    setFileName(responseFileName);
                } else {
                    setMsg('Your file is not loading')
                    setLoading(false)
                }
            } catch (err) {
                setMsg(err?.response?.data?.msg)
                setLoading(false)
                notifyError(err?.response?.data?.msg);
            }
        }
        getDocById();
    }, [match.params.id]);

    async function handleDownloadFile() {
        const {
            data: { url, file_name },
        } = await axios.get(`${CONFIG.BASE_URL}/file/download/${fileId}`, {
            withCredentials: true,
        });
        FileSaver.saveAs(url, file_name);
    }

    const handleViewDetail = () => {
        emitter.emit(POPUP_EVENTS.SHOW_FILE_PROPERTIES, fileId);
    };
    const handleShareFile = () => {
        emitter.emit(POPUP_EVENTS.SHARE_FILE);
    };


    // const onCreate = (fileNames) => {
    //     console.log("fileSrc :",fileNames);
    //     var uploadDocument = new FormData();
    //     uploadDocument.append('DocumentName', fileNames);
    // }

    // const readUploadFile = (fileSrc) => { 
    //     var a = window.createElement("a");
    //     window.body.appendChild(a);
    //     a.style = "display: block";
    //     return function (fileSrc) {
    //         var json = JSON.stringify(fileSrc),
    //             blob = new Blob([json], { type: "text/stream" }),
    //             url = window.URL.createObjectURL(blob);
    //         a.href = url;
    //         a.download = fileSrc;
    //         a.click();
    //         window.URL.revokeObjectURL(url);
    //     };
    // }

    return (
        <div id="file" className='details-files-section'>
            <div className="file-details-nav">
                <div className="file-title-wrp">
                    <i className="icon-arrow-left" onClick={goBack}><MdKeyboardBackspace /></i>
                    <span className='title-file'><i><img src={getDefaultThumbnail(fileType)} alt="" /></i>{fileName}</span>
                </div>
                <div className="button-list">
                    <button
                        data-tip="Linked Files"
                        data-for="links"
                        type="button"
                        style={{ position: 'relative' }}
                        onClick={() => {
                            emitter.emit(POPUP_EVENTS.SHOW_LINK_FILES, true);
                            dispatch(checkLinkedFiles(links));
                        }}
                    >
                        <i className="icon-link"><BsLink45Deg /></i>
                        <div className="link-count">
                            {links?.length || 0}
                        </div>
                    </button>
                    <button type="button" data-tip="Download File"
                        data-for="download" onClick={handleDownloadFile}
                    >
                        <i className="icon-download-circle"><BsDownload /></i>
                    </button>
                    <button type="button" data-tip="Share File"
                        data-for="share" onClick={handleShareFile}
                    >
                        <i className="icon-share"><BsShareFill /></i>
                    </button>
                    <button type="button">
                        <i className="icon-info" data-tip="Info"
                            data-for="info" onClick={handleViewDetail}
                        ><BsInfo /></i>
                    </button>
                    <ReactTooltip id="links" />
                    <ReactTooltip id="download" />
                    <ReactTooltip id="share" />
                    <ReactTooltip id="info" />
                </div>
            </div>
            <div className="file-content">
                <div className='viewer-file-main'>
                    <Scrollbars
                        renderView={scrollbar.renderView}
                        renderTrackHorizontal={scrollbar.renderTrackHorizontal}
                        renderTrackVertical={scrollbar.renderTrackVertical}
                        renderThumbHorizontal={scrollbar.renderThumb}
                        renderThumbVertical={scrollbar.renderThumb}
                        universal={true}
                    >
                        <div className='viewer-file'>
                            {renderView()}
                            {/* <DocumentEditorContainerComponent
                                height={'590px'} id="container"
                                serviceUrl="https://ej2services.syncfusion.com/production/web-services/api/documenteditor/"
                                enableToolbar={true} created={onCreate} /> */}
                        </div>
                    </Scrollbars>
                </div>
                <div className='commet-bar-wrap'>                              
                    <Scrollbars
                        renderView={scrollbar.renderView}
                        renderTrackHorizontal={scrollbar.renderTrackHorizontal}
                        renderTrackVertical={scrollbar.renderTrackVertical}
                        renderThumbHorizontal={scrollbar.renderThumb}
                        renderThumbVertical={scrollbar.renderThumb}
                        universal={true}
                    >
                        <FileDetailSideBar
                            links={links}
                            fileId={match.params.id}
                            initialComments={initialComments}
                            fileSrc={fileSrc}
                            fileType={fileType}
                        />
                    </Scrollbars>
                </div>
            </div >
            <LinkFiles hideAction />
        </div >
    );
}
FileDetail.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.object.isRequired,
    }).isRequired,
};
