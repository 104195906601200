import { useEffect, useRef } from "react";

export default function PdfViewerComponent(props) {
  const showSignatureOption = props?.showSignatureOption
  const containerRef = useRef(null);

  function generatePKCS7({ fileContents }) {
    // const certificatePromise = fetch("certs/certificate.pem").then((response) =>
    //   response.text()
    // );
    // const privateKeyPromise = fetch("certs/private-key.pem").then((response) =>
    //   response.text()
    // );
    return new Promise((resolve, reject) => {
      Promise.all([])
        .then(([]) => {
          const privateKeyPem = `-----BEGIN PRIVATE KEY-----
          MIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQDCRgQWMde83xVJ
          glzLQtXqkiJLVEUf4jdDv6jFovn5F5Bl8PsSibh6kYGZlMBjkr5DEJAt6PcJ0rk6
          bov/4bzYVxsHYE63gBNYybCwb+8ArprDJSxdowCiOG+MkrF1ndgDcZHFrFfFzYy1
          J071KZM3fyEoVxoOiaZW/905gDlw2nLlj1RCDTgRU9DGd3trSaqQtRE7dt6ibQhl
          asAVS7DchFTdRlGYo/A/IotQgJO0K8FIz12PdiDilc8kqto6PkbhMLxEtgNX+7CA
          bTIgLyz4Sf/KBcLdO9rnq7w+h9KpkmSsdTS2mVrIhTIkNaW9bZ202xwYQoRr1Bgb
          99+UieZfAgMBAAECggEAMPP3n6gblqoX8Rf6TfWfdaYxIg4wBOK5zkaDPpncp1m6
          txVMDtXJOIllwM9s0yps6vVHf2oOpdkFd6XXfTjinxkD5I3uB+sr9NmNvkfWT7Ov
          p7C8RzQFutrBsQhd0yddATEi7E6LJ3quFA+9o6VARWGxdzrLhBJHafC7S/v1fWv9
          mVBNoZx91LexN7j8jtgmmgs1cUS2UDo2CS8viK9NNgGsIb6trZCcbh3s9ZlkA9l2
          tGxXwsvnXhjJ6MLmYo+kxRIFKM0Z5sH+Bl6/5VmE98ID+OCiMtVDCmEHOT7HNpmT
          RWrNPFYVI0KJN3JdfI81zcBJmVe/OyO2p+iVsehWwQKBgQDuM9iYAHyShAf7Z0xd
          Jo4TXThhtXInjgVPkqd+qv+QbfF87IeO8pqirXq2PYHkN+/EW0tqXtakqF7chGSn
          4qW7bkQQp9r1POBUpXAEovF37fkBTQeiZ2DFegRC1Yc5AHAgKBZf+k9m3zNv/lUN
          QRsXnl+03uFy1hqbnCv+EMkp0QKBgQDQye3wWBpgBbEvwem5+SJQwcxftjosKtAb
          vEQQ5V7K7wJsvNT32QCfOOK5JZ9yezAyqNNJTSbgjmtITA4H2BN0dsG57IA7xubT
          bqYlNalw74tw2SCOOQjJGiDcbsThInC1soDVDxSCkiwUxhzKexJHIoKbMUwZ4VQj
          LruUNszpLwKBgGQDltSzl5p5H1TamYCfGJZe1LaJ1cVv5yDzBaq0p5t/3h5CveQy
          TuUxC84K40fsiJqcbnzaFyuJmlLir9Sh4E5jTOblY5UGm3ZPBEqeE970J1H5gtQY
          rdQcmD9rolw3p9yImGRyF/asjs9eLV3kUTjIgYzS7DwoU4G0v+/sIlqRAoGAbdJJ
          PaqZ9PgJgz1UW2YYDrJhdda8Mo8yd+RHSTvc6JFfjSKmQtf548ZgO1L205jIeV0k
          BeJ3GJbRB2450xZs5U2yCMHFkavFrmD9M4VOYwEdL3aGEUkevejocAoztNgQwQHr
          kiYke6m3VhX+/uJNcu1MFaWQiexj/T2PnR5WGjECgYEAnalg1WSw/jTumZp2mf/r
          ClpONa+3LVwhCsQwqpZbs8A9okgmGU3Dfh8ZM9uWaO5OWMlwv7zVDSB5d89gJJm/
          p5lopqdxRQ8wBH1k291UPx7UD5975jWrHnnddgynrUlInk4QpHyqXlnFS3AFXa5F
          SEIdFZiSD3T+vNc2VhxoU8U=
          -----END PRIVATE KEY-----`;
          const certificatePem = `-----BEGIN CERTIFICATE-----
          MIIDlDCCAnwCCQDFtY+YCW8r0DANBgkqhkiG9w0BAQsFADCBizELMAkGA1UEBhMC
          SU4xEDAOBgNVBAgMB0d1amFyYXQxDjAMBgNVBAcMBVN1cmF0MQ4wDAYDVQQKDAVK
          ZW5pczETMBEGA1UECwwKamVuaXNfdW5pdDEOMAwGA1UEAwwFamVuaXMxJTAjBgkq
          hkiG9w0BCQEWFmplbnVnYWRoaXlhOEBnbWFpbC5jb20wHhcNMjMwMjEzMTc1OTU2
          WhcNMjQwMjEzMTc1OTU2WjCBizELMAkGA1UEBhMCSU4xEDAOBgNVBAgMB0d1amFy
          YXQxDjAMBgNVBAcMBVN1cmF0MQ4wDAYDVQQKDAVKZW5pczETMBEGA1UECwwKamVu
          aXNfdW5pdDEOMAwGA1UEAwwFamVuaXMxJTAjBgkqhkiG9w0BCQEWFmplbnVnYWRo
          aXlhOEBnbWFpbC5jb20wggEiMA0GCSqGSIb3DQEBAQUAA4IBDwAwggEKAoIBAQDC
          RgQWMde83xVJglzLQtXqkiJLVEUf4jdDv6jFovn5F5Bl8PsSibh6kYGZlMBjkr5D
          EJAt6PcJ0rk6bov/4bzYVxsHYE63gBNYybCwb+8ArprDJSxdowCiOG+MkrF1ndgD
          cZHFrFfFzYy1J071KZM3fyEoVxoOiaZW/905gDlw2nLlj1RCDTgRU9DGd3trSaqQ
          tRE7dt6ibQhlasAVS7DchFTdRlGYo/A/IotQgJO0K8FIz12PdiDilc8kqto6Pkbh
          MLxEtgNX+7CAbTIgLyz4Sf/KBcLdO9rnq7w+h9KpkmSsdTS2mVrIhTIkNaW9bZ20
          2xwYQoRr1Bgb99+UieZfAgMBAAEwDQYJKoZIhvcNAQELBQADggEBAEtJMcdZddmI
          gC8xyDUkrgl1aRytNWR2K43FwzKOyrSokoNKAnvoARke4h8WhqC+t2MXIAXzZF2Y
          kZzdwg48yzgqqN/8k4mgGK2NtCSrbEZN0GPWKgleNV4/eNBUXiBY8nXti+YuXNsA
          oyye/QLftQUHufLMRJAMrWus6oJsWE1T/17l2dl9o3+/iq6Uu4JhCNozrTP2O8Ww
          zmcJinCYCk05HXvIMbvcUSTCzBjptJUBNMJx3m2GLkV5jNMRVcXgY/tOyWCuYwb8
          9aOcl3AO75z5yLdHv6w/u2c+ssxnkQtw68Ldt12UzKwy828ZtICAhmWI4HalJ4jA
          dLtoHLekmRQ=
          -----END CERTIFICATE-----`;
          const forge = window.forge;
          const certificate = forge.pki.certificateFromPem(certificatePem);
          const privateKey = forge.pki.privateKeyFromPem(privateKeyPem);

          const p7 = forge.pkcs7.createSignedData();
          p7.content = new forge.util.ByteBuffer(fileContents);
          p7.addCertificate(certificate);
          p7.addSigner({
            key: privateKey,
            certificate: certificate,
            digestAlgorithm: forge.pki.oids.sha256,
            authenticatedAttributes: [
              {
                type: forge.pki.oids.contentType,
                value: forge.pki.oids.data,
              },
              {
                type: forge.pki.oids.messageDigest,
              },
              {
                type: forge.pki.oids.signingTime,
                value: new Date(),
              },
            ],
          });

          p7.sign({ detached: true });
          const result = stringToArrayBuffer(
            forge.asn1.toDer(p7.toAsn1()).getBytes()
          );
          resolve(result);
        })
        .catch(reject);
    });
  }

  function stringToArrayBuffer(binaryString) {
    const buffer = new ArrayBuffer(binaryString.length);
    let bufferView = new Uint8Array(buffer);
    for (let i = 0, len = binaryString.length; i < len; i++) {
      bufferView[i] = binaryString.charCodeAt(i);
    }
    return buffer;
  }

  useEffect(() => {
    const container = containerRef.current;
    let PSPDFKit;

    (async function () {
      PSPDFKit = await import("pspdfkit");
      await PSPDFKit.load({
        container,
        document: props.document,
        baseUrl: `${window.location.protocol}//${window.location.host}/${process.env.PUBLIC_URL}`,
        toolbarItems: [...PSPDFKit.defaultToolbarItems?.filter(item=>(item?.type !== "signature" || showSignatureOption))]
      }).then(async function (instance) {
        const annotations = await instance.getAnnotations(0);
        const annotation = annotations.get(0);
        await instance.delete(annotation);

        const widget = new PSPDFKit.Annotations.WidgetAnnotation({
          pageIndex: 0,
          boundingBox: new PSPDFKit.Geometry.Rect({
            left: 200,
            top: 0,
            width: 250,
            height: 150,
          }),
          formFieldName: "My signature form field",
          id: PSPDFKit.generateInstantId(),
        });
        if(showSignatureOption){
          const formField = new PSPDFKit.FormFields.SignatureFormField({
            name: "My signature form field",
            annotationIds: PSPDFKit.Immutable.List([widget.id]),
          });
          instance.create([widget, formField]);
        }
        
        // setTimeout(() => {
        //   instance
        //     .signDocument(null, generatePKCS7)
        //     .then(() => {
        //       console.log("document signed.");
        //     })
        //     .catch((error) => {
        //       console.error("The document could not be signed.", error);
        //     });
        // }, 1000);
      });

      // await PSPDFKit.load({
      //   // Container where PSPDFKit should be mounted.
      //   container,
      //   // The document to open.
      //   document: props.document,
      //   // Use the public directory URL as a base URL. PSPDFKit will download its library assets from here.
      //   baseUrl: `${window.location.protocol}//${window.location.host}/${process.env.PUBLIC_URL}`,
      // });
    })();

    return () => PSPDFKit && PSPDFKit.unload(container);
  }, [props.document,showSignatureOption]);

  return <div ref={containerRef} style={{ width: "100%", height: "100vh" }} />;
}
